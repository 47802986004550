import {
  CustomerListType, CustomerQueueType, UpdateCustomerParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllCustomerService = async (params: BaseFilterParams):
  Promise<APIPaginationResponse<CustomerListType[]>> => {
  const res = await axiosInstance.get('customers', { params });
  return res.data;
};

export const getDetailCustomerService = async (id: number):
  Promise<CustomerListType> => {
  const res = await axiosInstance.get(`customers/${id}`);
  return res.data.data;
};

export const getAllCustomerQueueService = async (params: BaseFilterParams):
  Promise<APIPaginationResponse<CustomerQueueType[]>> => {
  const res = await axiosInstance.get('customers/queue', { params });
  return res.data;
};

export const postCustomerDequeueService = async (id: number):
  Promise<void> => {
  const res = await axiosInstance.post(`customers/dequeue/${id}`);
  return res.data;
};

export const updateCustomerService = async (
  { id, ...props }: UpdateCustomerParams & { id: number }
):
  Promise<void> => {
  await axiosInstance.put(`customers/${id}`, props);
};

export const exportCustomerService = async (params: BaseFilterParams):
  Promise<{ link: string }> => {
  const response = await axiosInstance.get('customers/exports', { params });
  return response.data.data;
};
