import {
  EPromotionStatus, TNotifyPromotionParams, TPromotion, TPromotionCode, TPromotionRequest
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllPromotionService = async (params: BaseFilterParams)
  : Promise<APIPaginationResponse<TPromotion[]>> => {
  const res = await axiosInstance.get('promotion-programs', { params });
  return res.data;
};

export const getPromotionByIdService = async (id: number)
  : Promise<TPromotion> => {
  const res = await axiosInstance.get(`promotion-programs/${id}`);
  return res.data.data;
};

export const getPromotionCodesByIdService = async (
  { id, params }: { id: number, params: BaseFilterParams }
): Promise<APIPaginationResponse<TPromotionCode[]>> => {
  const res = await axiosInstance.get(`promotion-programs/${id}/codes`, { params });
  return res.data;
};

export const createPromotionService = async (req: TPromotionRequest)
  : Promise<{ data: { id: number } }> => {
  const res = await axiosInstance.post('promotion-programs', req);
  return res.data;
};

export const updatePromotionService = async (
  { id, reqData }: { id: number, reqData: TPromotionRequest }
): Promise<void> => {
  await axiosInstance.put(`promotion-programs/${id}`, reqData);
};

export const updateStatusPromotionService = async (
  { id, status }: { id: number, status: EPromotionStatus }
): Promise<void> => {
  await axiosInstance.put(`promotion-programs/${id}/update-status`, { status });
};

export const notifyPromotionService = async (req: TNotifyPromotionParams)
  : Promise<void> => {
  const { id, data } = req;
  await axiosInstance.post(`promotion-programs/${id}/notify`, data);
};
