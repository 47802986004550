import { yupResolver } from '@hookform/resolvers/yup';
import {
  Card,
  Col,
  Row,
  Typography
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { orderStatusDummy } from 'common/assets/dummyData/system';
import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  getDetailOrderService
} from 'common/services/extends/order';
import { formatDateTime } from 'common/utils/functions';
import { useSchema } from 'common/utils/schemas';

type MainDataTypes = {
  unitPrice: number
  unitPriceDuration: number
  unitPricePromotionDuration: number
  promotionStart: string
  promotionEnd: string
  tax: number
  customerName: string
  customerEmail: string
  customerPhone: string
  usageTime: number
  status: number
  dateBilling: string
  totalPrice: number
  paypalOrderId: string
};

const EditOrder: React.FC<ActiveRoles> = () => {
  const { orderDataSchema } = useSchema();

  const mainMethod = useForm<MainDataTypes>({
    resolver: yupResolver(orderDataSchema),
    mode: 'onChange',
    defaultValues: {
      unitPrice: 0,
      unitPriceDuration: 0,
      tax: 0,
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      usageTime: 0,
      status: 0,
      dateBilling: '',
    }
  });

  /* Selectors */

  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* Queries */
  const queryKeyDetail = ['getDetailOrder', idParam];
  const { data: detailStaticBlock } = useQuery(
    queryKeyDetail,
    () => {
      if (idParam) {
        return getDetailOrderService(idParam);
      }
      return undefined;
    }
  );

  /* Effects */
  useEffect(() => {
    if (detailStaticBlock) {
      mainMethod.reset({
        unitPrice: detailStaticBlock.orderData.unitPrice,
        unitPriceDuration: detailStaticBlock.orderData.unitPriceDuration,
        tax: detailStaticBlock.orderData.tax,
        customerName: detailStaticBlock.orderData.customer.name,
        customerEmail: detailStaticBlock.orderData.customer.email,
        customerPhone: detailStaticBlock.orderData.customer.phone,
        usageTime: detailStaticBlock.orderData.usageTime,
        status: detailStaticBlock.orderData.status,
        dateBilling: formatDateTime(detailStaticBlock.orderData.dateBilling),
        totalPrice: detailStaticBlock.orderData.totalPrice,
        paypalOrderId: detailStaticBlock.orderData.paypalOrderId,
        unitPricePromotionDuration: detailStaticBlock.orderData.unitPricePromotionDuration,
        promotionStart: detailStaticBlock.orderData.promotionStart,
        promotionEnd: detailStaticBlock.orderData.promotionEnd,
      });
    } else {
      mainMethod.reset();
    }
  }, [detailStaticBlock, mainMethod]);

  /* Render */
  return (
    <div className="p-editStaticBlock">
      <HeaderPage
        fixed
        title={idParam ? t('order.editOrderTitle') : t('order.createOrderTitle')}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            <Card
              type="inner"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text strong>
                    {t('system.name')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="customerName"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    {t('system.email')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="customerEmail"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('system.phone')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="customerPhone"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('system.status')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="status"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          style={{ color: '#4a4a4a', cursor: 'pointer' }}
                        >
                          {orderStatusDummy.find((item) => item.value === value)?.label}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      Transaction ID:
                    </Typography.Text>
                    <Controller
                      name="paypalOrderId"
                      control={mainMethod.control}
                      render={({
                        field: { value },
                      }) => (
                        <span className="p-editPageTemplate_textWrapper">
                          <Typography.Text
                            className="u-mt-8"
                          >
                            {value}
                          </Typography.Text>
                        </span>
                      )}
                    />
                  </div>
                </Col>
                <Col span={12} className="u-mt-16">
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('order.unitPrice')}
                      {': '}
                    </Typography.Text>
                    <Controller
                      name="unitPrice"
                      control={mainMethod.control}
                      render={({
                        field: { value },
                      }) => (
                        <span className="p-editPageTemplate_textWrapper">
                          <Typography.Text
                            className="u-mt-8"
                          >
                            {value}
                          </Typography.Text>
                        </span>
                      )}
                    />
                  </div>
                </Col>
                <Col span={12} className="u-mt-16">
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('order.unitPriceDuration')}
                      {': '}
                    </Typography.Text>
                    <span className="p-editPageTemplate_textWrapper">
                      <Typography.Text
                        className="u-mt-8"
                      >
                        {mainMethod.getValues('unitPricePromotionDuration')
                          ? mainMethod.getValues('unitPricePromotionDuration')
                          : mainMethod.getValues('unitPriceDuration')}
                      </Typography.Text>
                    </span>
                  </div>
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.tax')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="tax"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.totalPrice')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="totalPrice"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.usageTime')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="usageTime"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.dateBilling')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="dateBilling"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionStart')}
                    {': '}
                  </Typography.Text>
                  <span className="p-editPageTemplate_textWrapper">
                    <Typography.Text>
                      {formatDateTime(mainMethod.getValues('promotionStart'))}
                    </Typography.Text>
                  </span>
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionEnd')}
                    {': '}
                  </Typography.Text>
                  <span className="p-editPageTemplate_textWrapper">
                    <Typography.Text>
                      {formatDateTime(mainMethod.getValues('promotionEnd'))}
                    </Typography.Text>
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={detailStaticBlock ? moment(detailStaticBlock?.orderData?.createdAt).fromNow() : ''}
              createdBy={detailStaticBlock?.orderData?.customer.name || ''}
              lastUpdated={detailStaticBlock ? moment(detailStaticBlock?.orderData?.updatedAt).fromNow() : ''}
              lastUpdatedBy={detailStaticBlock?.orderData?.customer.name || ''}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditOrder;
