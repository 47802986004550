import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card, Col, Empty,
  Row, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import UpdateSubmitFormModal from './UpdateSubmitFormModal';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { StatusHandleLabel, StatusHandleLabelType } from 'common/components/StatusLabel';
import { getAllSubmitFormListByFormService, getSubmitFormByIdService, updateStatusSubmitFormWithManyIdService } from 'common/services/forms';
import { SubmitFormByIdData } from 'common/services/forms/types';
import { enumToMap } from 'common/utils/enumHelper';
import { formatDateTime } from 'common/utils/functions';

type FormProps = {
  id: number;
  formCode: string;
  status: number;
  updatedAt: string;
};

const SubmitFormListByFormManagement: React.FC<ActiveRoles> = ({
  roleUpdate
}) => {
  /* Hook */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  /* Store */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));

  /* States */
  const [keyword, setKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [updateModal, setUpdateModal] = useState<{
    open: boolean;
    submitFormData?: SubmitFormByIdData;
  }>({
    open: false,
    submitFormData: undefined
  });

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    ['submit-form-list-by-form', currentPage, keyword, idParams, currentView],
    () => getAllSubmitFormListByFormService({
      id: idParams,
      page: currentPage,
      limit: currentView
    }),
    { keepPreviousData: true, enabled: !!idParams }
  );

  const { mutate: changeStatusMutate, isLoading: changeStatusLoading } = useMutation(
    ['submit-form-changeStatusManyId'],
    async (data:
      {
        ids: number[], status: number
      }) => updateStatusSubmitFormWithManyIdService(data.ids, data.status),
    {
      onSuccess: () => {
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(['submit-form-list-by-form']);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  const { mutate: getSubmitFormByIdMutate, isLoading: submitFormByIdLoading } = useMutation(
    ['get-submit-form-by-id'],
    async (data:
      {
        id: number
      }) => getSubmitFormByIdService(data.id),
    {
      onSuccess: (res: SubmitFormByIdData) => {
        setUpdateModal({
          open: true,
          submitFormData: res
        });
      },
    }
  );

  /* Functions */
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  /* Data */
  const columns: ColumnsType<FormProps> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: FormProps) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Code
    {
      title: t('system.code'),
      dataIndex: 'formCode',
      key: 'formCode',
      sorter: {
        compare: (a: FormProps, b: FormProps) => a.formCode.localeCompare(b.formCode),
      },
      sortDirections: ['descend', 'ascend'],
    },
    // --- Status
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: FormProps) => (
        <StatusHandleLabel status={data.status} />
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: FormProps, b: FormProps) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: FormProps) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: FormProps) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => {
              getSubmitFormByIdMutate({ id: _data.id });
            }}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, getSubmitFormByIdMutate]);

  const tableData: FormProps[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.id,
      formCode: item.formCode,
      status: item.status,
      updatedAt: item.updatedAt
    })) || []), [listData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.typeFormSubmit')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || submitFormByIdLoading}
          handleSearch={handleSearch}
          roles={{
            roleDelete: true,
            roleCreate: false,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'formCode', 'status', 'updatedAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          statusDataTable={{
            handleChangeStatus:
              (data, status) => changeStatusMutate({ ids: data.map((item) => item.id), status }),
            changeStatusLoading,
            statusOptions: Array.from(enumToMap(
              StatusHandleLabelType
            ).entries())
              // Remove waiting option
              .slice(1)
              .map((ele) => ({
                label: t(`system.${ele[0]}HandleSave`),
                value: ele[1],
              })),
            canChangeStatusApprove: true,
          }}
        />
        <UpdateSubmitFormModal
          open={updateModal.open}
          submitForm={updateModal.submitFormData}
          handleClose={() => {
            setUpdateModal({
              open: false,
              submitFormData: undefined
            });
          }}
        />
        <Card style={{ marginTop: '20px' }}>
          <Typography.Title level={5}>
            Form Data
          </Typography.Title>
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col span={12}>
              <Typography.Text>{`${t('system.name')}: ${listData?.formData.name || ''}`}</Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text>{`${t('system.code')}: ${listData?.formData.code || ''}`}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>Fields:</Typography.Text>
              {
                listData && listData?.formData.fields.length > 0
                  ? listData?.formData.fields.map((item) => (
                    <Card type="inner" style={{ marginTop: '8px' }}>
                      <Typography.Text>{`inputName: ${item.inputName || ''}`}</Typography.Text>
                      <Row gutter={[16, 16]} style={{ marginTop: '8px' }}>
                        <Col span={12}>
                          <Typography.Text>{`vi: ${item.label?.vi || ''}`}</Typography.Text>
                        </Col>
                        <Col span={12}>
                          <Typography.Text>{`en: ${item.label?.en || ''}`}</Typography.Text>
                        </Col>
                      </Row>
                    </Card>
                  )) : <Empty />
              }
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default SubmitFormListByFormManagement;
