import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Typography
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  createAIService, getByIdAIService, updateAIService, updateApiKeyService
} from 'common/services/extends/aiServer';
import { CreateAIParams, UpdateAIParams } from 'common/services/extends/aiServer/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { ModuleAIServerDetailSchema } from 'common/utils/schemas';
import roles from 'configs/roles';
import { StatusLabelAIServer } from 'extends/AIServerManagement/StatusUsing';

type AIServerFormData = {
  name: string;
  code: string;
  apiKey: string;
};

const ModuleAIServerDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));
  const method = useForm<AIServerFormData>({
    defaultValues: {
      name: '',
      code: '',
      apiKey: '',
    },
    resolver: yupResolver(ModuleAIServerDetailSchema),
  });

  const rolesSystem = useAppSelector((state) => state.auth.roles);

  const queryClient = useQueryClient();

  const { data: dataAiServer, isLoading: aiServerDataLoading } = useQuery(
    ['getByIdAIServerDetail', { idParams }],
    () => getByIdAIService(idParams),
    {
      enabled: !!idParams
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['aIServer-create'],
    async (params: CreateAIParams) => createAIService(params),
    {
      onSuccess: () => {
        navigate(`${ROUTE_PATHS.AI_SERVER_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['aIServer-update'],
    async (params: UpdateAIParams) => updateAIService(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getByIdAIServerDetail']);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }

    }
  );

  const onSubmit = async () => {
    const formData = method.getValues();
    if (idParams) {
      updateMutate({
        id: idParams,
        name: formData.name,
        code: formData.code,
      });
    } else {
      createMutate({
        name: formData.name,
      });
    }
  };
  const handleSubmitApiKey = async () => {
    const formData = method.getValues();
    const response = await updateApiKeyService(String(idParams), { apiKey: formData.apiKey });
    method.setValue('apiKey', response.apiKey);
  };

  useEffect(() => {
    if (dataAiServer) {
      method.reset({
        name: dataAiServer?.aiServerData?.name,
        code: dataAiServer?.aiServerData?.code,
        apiKey: dataAiServer?.aiServerData?.apiKey,
      });
    }
  }, [dataAiServer, method]);

  return (
    <FormProvider<AIServerFormData> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('aiServer.editAiServer') : t('aiServer.createAiServer')}
        rightHeader={(
          <Button
            type="primary"
            loading={aiServerDataLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            <Card>
              <div className="u-mt-16">
                <Row gutter={16}>
                  <Col span={idParams ? 12 : 24}>
                    <Typography.Text strong>
                      {t('aiServer.name')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="name"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          name="name"
                          placeholder="Nhập Thông tin"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  {
                    !!idParams && (
                      <>
                        <Col span={12}>
                          <Typography.Text strong>
                            {t('aiServer.code')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="code"
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="code"
                                placeholder="Nhập Thông tin"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                disabled
                              />
                            )}
                          />
                        </Col>
                        <Col span={12} className="u-mt-16">
                          <Typography.Text strong>
                            {t('aiServer.onlineStatus')}
                          </Typography.Text>
                          :
                          <Typography.Text strong className="u-ml-4">
                            {dataAiServer?.aiServerData?.isOnline ? t('aiServer.online') : t('aiServer.offline')}
                          </Typography.Text>
                        </Col>
                        <Col span={12} className="u-mt-16">
                          <Typography.Text strong>
                            {t('aiServer.usageStatus')}
                          </Typography.Text>
                          :
                          <StatusLabelAIServer status={dataAiServer?.aiServerData.statusUsing || 'stopped'} notDot />
                        </Col>
                        <Col
                          span={24}
                          className="u-mt-24"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography.Text strong>
                            {t('aiServer.apiKey')}
                            {' '}
                          </Typography.Text>
                          :
                          <Typography.Text strong className="u-ml-10">
                            {method.watch('apiKey')}
                          </Typography.Text>
                          <Button
                            className="u-ml-20"
                            type="primary"
                            onClick={handleSubmitApiKey}
                            disabled={!rolesSystem.includes(roles.AI_SERVER_GENERATE_API_KEY) && !rolesSystem.includes('*')}
                          >
                            {t('aiServer.generate')}
                          </Button>
                        </Col>
                      </>
                    )
                  }
                </Row>
              </div>
            </Card>
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={dataAiServer ? moment(dataAiServer?.aiServerData?.createdAt).fromNow() : ''}
              createdBy={dataAiServer?.creator?.name || ''}
              lastUpdated={dataAiServer ? moment(dataAiServer?.aiServerData?.updatedAt).fromNow() : ''}
              lastUpdatedBy={dataAiServer?.updater?.name || ''}
            />
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
};

export default ModuleAIServerDetail;
