import {
  OrderListType,
  OrderDetailType,
  CustomerUsageHistoriesType
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllOrderService = async (params: BaseFilterParams):
  Promise<APIPaginationResponse<OrderListType[]>> => {
  const res = await axiosInstance.get('orders', { params });
  return res.data;
};

export const getDetailOrderService = async (id: number):
  Promise<OrderDetailType> => {
  const res = await axiosInstance.get(`orders/${id}`);
  return res.data.data;
};

export const getCustomerUsageHistoriesService = async (id: string, params: BaseFilterParams):
  Promise<APIPaginationResponse<CustomerUsageHistoriesType[]>> => {
  const res = await axiosInstance.get(`customer-usage-histories/${id}`, { params });
  return res.data;
};

export const getCustomerUsageHistoriesDetailService = async (id: number):
  Promise<CustomerUsageHistoriesType> => {
  const res = await axiosInstance.get(`customer-usage-histories/${id}`);
  return res.data.data;
};

export const exportOrdersService = async (params: BaseFilterParams):
  Promise<{ link: string }> => {
  const response = await axiosInstance.get('orders/exports', { params });
  return response.data.data;
};
