import {
  TPackage,
  TPackageDetail,
  TPackageLogs,
  TPackageRequest,
  EPackageStatus
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllPackageService = async (params: BaseFilterParams)
  : Promise<APIPaginationResponse<TPackage[]>> => {
  const res = await axiosInstance.get('packages', { params });
  return res.data;
};

export const getPackageByIdService = async (id: number)
  : Promise<TPackageDetail> => {
  const res = await axiosInstance.get(`packages/${id}`);
  return res.data.data;
};

export const createPackageService = async (data: TPackageRequest)
  : Promise<{ id: number }> => {
  const res = await axiosInstance.post('packages', data);
  return res.data;
};

export const updatePackageService = async ({ id, data }: { id: number, data: TPackageRequest })
  : Promise<void> => {
  await axiosInstance.put(`packages/${id}`, data);
};

export const updateStatusPackageService = async (
  { id, status }: { id: number, status: EPackageStatus }
)
  : Promise<void> => {
  await axiosInstance.put(`packages/${id}/update-status`, { status });
};

export const getPackageLogsService = async (
  { id, params }: { id: number, params: BaseFilterParams }
)
  : Promise<APIPaginationResponse<TPackageLogs[]>> => {
  const res = await axiosInstance.get(`packages/${id}/logs`, { params });
  return res.data;
};

export const getPackageLogsByIdService = async (
  { packageId, logId }: { packageId: number, logId: number }
)
  : Promise<TPackageLogs> => {
  const res = await axiosInstance.get(`packages/${packageId}/logs/${logId}`);
  return res.data.data;
};
