import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Button, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import {
  exportCustomerService,
  getAllCustomerService
} from 'common/services/extends/customer';
import { Gender, Status } from 'common/services/extends/customer/types';
import { ROUTE_PATHS, statusType, genderType } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';

export type TableDataTypes = {
  id: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  status: Status;
  gender: Gender;
  serverCode: string | null;
  serverLink: string | null;
  facebookId: string | null;
  googleId: string | null;
  appleId: string | null;
};

const CustomerManagement: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);
  const { roles: role } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const queryKey = ['customers-list', keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getAllCustomerService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      keepPreviousData: true
    }
  );

  const { mutate: exportMute, isLoading: isLoadingExport } = useMutation(
    ['export-customer'],
    () => exportCustomerService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess: (res) => {
        if (res.link) {
          message.success(t('message.exportSuccess'));
          window.open(res.link, '_blank');
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      id: val.customerData.id,
      name: val.customerData.name,
      email: val.customerData.email,
      phone: val.customerData.phone,
      address: val.customerData.address,
      createdAt: val.customerData.createdAt,
      updatedAt: val.customerData.updatedAt,
      status: val.customerData?.status,
      gender: val.customerData?.gender,
      serverLink: val.customerData.serverLink,
      serverCode: val.customerData.serverCode,
      facebookId: val.customerData.facebookId,
      googleId: val.customerData.googleId,
      appleId: val.customerData.appleId
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name?.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.email?.localeCompare(b.email)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.email}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.phone?.localeCompare(b.phone)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('system.address'),
      dataIndex: 'address',
      key: 'address',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.address?.localeCompare(b.address)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.address}
        </Typography.Text>
      ),
    },
    {
      title: t('system.gender'),
      dataIndex: 'gender',
      key: 'gender',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {(genderType.find((item) => item.value === data.gender))?.label}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.serverCode'),
      dataIndex: 'serverCode',
      key: 'serverCode',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.serverCode}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.serverLink'),
      dataIndex: 'serverLink',
      key: 'serverLink',
      width: 160,
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.serverLink}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.facebookId'),
      dataIndex: 'facebookId',
      key: 'facebookId',
      width: 160,
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.facebookId}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.googleId'),
      dataIndex: 'googleId',
      key: 'googleId',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.googleId}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.appleId'),
      dataIndex: 'appleId',
      key: 'appleId',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.appleId}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 90,
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {(statusType.find((item) => item.value === data.status))?.label}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.seeHistory'),
      key: 'seeHistory',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!role.includes(roles.CUSTOMER_USAGE_HISTORY_INDEX) && !role.includes('*')}
            icon={<EyeOutlined />}
            onClick={() => (role.includes(roles.CUSTOMER_USAGE_HISTORY_INDEX) || role.includes('*')) && navigate(`${ROUTE_PATHS.CUSTOMER_USAGE_HISTORIES_MANAGEMENT}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EditOutlined />}
            onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('customer', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams(mappingFilterToQuery(selectedFilterList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customer')}
        rightHeader={(
          <Button
            loading={isLoadingExport}
            disabled={!role.includes(roles.CUSTOMER_EXPORT) && !role.includes('*')}
            onClick={() => exportMute()}
          >
            {t('system.export')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'email', 'phone', 'status', 'serverCode', 'serverLink', 'facebookId', 'googleId', 'appleId', 'seeHistory', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listRes?.meta.total || 1,
            noBaseCol: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default CustomerManagement;
