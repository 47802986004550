/* eslint-disable react/no-danger */
import {
  Col, Modal, Row, Space, Spin, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getPackageLogsByIdService } from 'common/services/extends/package';
import { EPackageType } from 'common/services/extends/package/types';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';

interface Props {
  openData: {
    packageId: number,
    id: number,
  } | undefined
  handleClose: () => void
}

const DetailModal: React.FC<Props> = ({ openData, handleClose }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    ['get-package-log-detail'],
    () => getPackageLogsByIdService({ packageId: (openData?.packageId)!, logId: (openData?.id)! }),
    {
      enabled: !!openData
    }
  );

  return (
    <Modal
      title={t('package.logDetail')}
      width="40vw"
      visible={!!openData}
      onOk={handleClose}
      onCancel={handleClose}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
    >
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col span={12}>
            <Space>
              <Typography.Title level={5}>
                {t('package.name')}
                :
              </Typography.Title>
              <Typography.Text>
                {data?.packageLogData.name}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={12}>
            <Space>
              <Typography.Title level={5}>
                {t('package.price')}
                :
              </Typography.Title>
              <Typography.Text>
                {`${formatNumberWithSeparators(data?.packageLogData.price || 0)}$`}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="u-mt-8">
            <Space>
              <Typography.Title level={5}>
                {t('package.accessTime')}
                :
              </Typography.Title>
              <Typography.Text>
                {`${data?.packageLogData.accessTime} ${data?.packageLogData.accessTime && data?.packageLogData.accessTime > 1 ? t('package.minutes') : t('package.minute')}`}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="u-mt-8">
            <Space>
              <Typography.Title level={5}>
                {t('package.validityPeriod')}
                :
              </Typography.Title>
              <Typography.Text>
                {`${data?.packageLogData.validityPeriod} ${data?.packageLogData.validityPeriod && data?.packageLogData.validityPeriod > 1 ? t('package.months') : t('package.month')}`}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="u-mt-8">
            <Space>
              <Typography.Title level={5}>
                {t('package.action')}
                :
              </Typography.Title>
              <Typography.Text>
                {data?.packageLogData.action}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="u-mt-8">
            <Space>
              <Typography.Title level={5}>
                {t('package.type')}
                :
              </Typography.Title>
              <Typography.Text>
                {data?.packageLogData.type ? EPackageType[data?.packageLogData.type] : '-'}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="u-mt-8">
            <Space>
              <Typography.Title level={5}>
                {t('package.startDate')}
                :
              </Typography.Title>
              <Typography.Text>
                {formatDateTime(data?.packageLogData.startDate)}
              </Typography.Text>
            </Space>
          </Col>
          {data?.packageLogData.endDate && (
            <Col span={12} className="u-mt-8">
              <Space>
                <Typography.Title level={5}>
                  {t('package.endDate')}
                  :
                </Typography.Title>
                <Typography.Text>
                  {formatDateTime(data?.packageLogData.endDate)}
                </Typography.Text>
              </Space>
            </Col>
          )}
          <Col span={12} className="u-mt-8">
            <Space>
              <Typography.Title level={5}>
                {t('package.updater')}
                :
              </Typography.Title>
              <Typography.Text>
                {data?.updater ? `${data?.updater?.name} (${data?.updater?.email})` : `${t('package.system')}`}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24} className="u-mt-8">
            <Space style={{ alignItems: 'flex-start' }}>
              <Typography.Title level={5}>
                {t('package.description1')}
                :
              </Typography.Title>
              <Typography.Text>
                {data?.packageLogData.description1}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24} className="u-mt-8">
            <Space style={{ alignItems: 'flex-start' }} direction="vertical">
              <Typography.Title level={5}>
                {t('package.description2')}
                :
              </Typography.Title>
              <div style={{ paddingLeft: '10px' }} dangerouslySetInnerHTML={{ __html: data?.packageLogData.description2 || '' }} />
            </Space>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default DetailModal;
