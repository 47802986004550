export enum EPackageStatus {
  New = 1,
  Waiting = 7,
  Approved = 13,
  Inactive = 21
}

export enum EPackageFilter {
  INACTIVE = -1,
  REMAIN = -2
}

export enum EPackageType {
  Default = 0,
  Package = 1,
  Free = 2
}

export type TPackage = {
  packageData: {
    id: number,
    name: string,
    accessTime: number,
    validityPeriod: number,
    price: number,
    startDate: string,
    endDate: string | null,
    status: EPackageStatus,
    type: EPackageType,
    createdAt: string,
    updatedAt: string,
    displayOrder: number
  },
  updater: CreatorType | null,
  creator: CreatorType | null
};

export type TPackageDetail = {
  packageData: TPackage['packageData'] & {
    description1: string,
    description2: string,
  };
  updater: TPackage['updater'],
  creator: TPackage['creator']
};

export type TPackageRequest = {
  name: string,
  accessTime: number,
  validityPeriod: number,
  price: number,
  startDate: string,
  endDate?: string | null,
  description1: string,
  description2: string,
  displayOrder: number
};

export type TPackageLogs = {
  packageLogData: TPackageDetail['packageData'] & {
    action: string,
  },
  updater: TPackage['updater'],
  creator: TPackage['creator']
};

export type TPackageLogDetail = {
  packageLogData: TPackageLogs['packageLogData'] & {
    description1: string,
    description2: string,
  },
  updater: TPackage['updater'],
  creator: TPackage['creator']
};
