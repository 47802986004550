import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Space,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  getAllCustomerQueueService, postCustomerDequeueService,
} from 'common/services/extends/customer';
import { Gender, Status } from 'common/services/extends/customer/types';
import { genderType, statusType, waitingServer } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';

export type TableDataTypes = {
  id: number;
  name: string;
  email: string;
  phone: string;
  status: Status;
  address: string;
  createdAt: string;
  updatedAt: string;
  gender: Gender;
  isWaiting: number;
  waitingAt: string;
};

const CustomerQueue: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* Selectors */
  const {
    defaultPageSize,
  } = useAppSelector((state) => state.system);
  const {
    roles: role
  } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  const queryKey = ['queue-list', keyword, currentPage, currentView];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getAllCustomerQueueService({
      keyword, page: currentPage, limit: currentView,
    }),
    {
      keepPreviousData: true
    }
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['customer-dequeue'],
    async (id: number) => postCustomerDequeueService(id),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['queue-list']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }

    }
  );

  /* Datas */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      id: val.customerData.id,
      name: val.customerData.name,
      email: val.customerData.email,
      phone: val.customerData.phone,
      address: val.customerData.address,
      createdAt: val.customerData.createdAt,
      updatedAt: val.customerData.updatedAt,
      status: val.customerData?.status,
      gender: val.customerData?.gender,
      isWaiting: val.customerData.waitingServer,
      waitingAt: val.customerData.waitingAt,
    })) || []), [listRes]);

  const handleResetStop = useCallback((data: TableDataTypes[]) => {
    deleteMutate(data[0].id);
  }, [deleteMutate]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.email.localeCompare(b.email)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.email}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.phone.localeCompare(b.phone)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('system.address'),
      dataIndex: 'address',
      key: 'address',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.address.localeCompare(b.address)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.address}
        </Typography.Text>
      ),
    },
    {
      title: t('system.gender'),
      dataIndex: 'gender',
      key: 'gender',
      width: 160,
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {(genderType.find((item) => item.value === data.gender))?.label}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 160,
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {(statusType.find((item) => item.value === data.status))?.label}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.isWaiting'),
      dataIndex: 'isWaiting',
      key: 'isWaiting',
      width: 160,
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {waitingServer.find((item) => item.value === data.isWaiting)?.label
            ? t(`customer.${(waitingServer.find((item) => item.value === data.isWaiting))?.label}`)
            : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.waitingAt'),
      dataIndex: 'waitingAt',
      key: 'waitingAt',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.waitingAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!role.includes(roles.CUSTOMER_DEQUEUE) && !role.includes('*')}
            icon={<DeleteOutlined />}
            onClick={() => (role.includes(roles.CUSTOMER_DEQUEUE) || role.includes('*'))
              && Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                title: t('message.confirmDeleteQueue'),
                onOk: () => {
                  handleResetStop([_data]);
                },
              })}
          />
        </Space>
      ),
    },
  ];

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerQueue')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading || deleteLoading}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'email', 'phone', 'address', 'gender', 'status', 'isWaiting', 'waitingAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listRes?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true
          }}
        />
      </div>
    </>
  );
};

export default CustomerQueue;
