import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { getAllTypeFormManagementService } from 'common/services/forms';
import { ROUTE_PATHS } from 'common/utils/constant';

type FormProps = {
  id: number;
  code: string;
  name: string;
  submitedCount: number;
};

const TypeFormManagement: React.FC<ActiveRoles> = () => {
  /* Hook */
  const { t } = useTranslation();
  const navigator = useNavigate();
  /* Store */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    ['type-formManagement-list', currentPage, keyword, currentView],
    () => getAllTypeFormManagementService({ keyword, page: currentPage, limit: currentView }),
    { keepPreviousData: true }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<FormProps> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: FormProps) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Code
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (a: FormProps, b: FormProps) => a.code.localeCompare(b.code),
      },
      sortDirections: ['descend', 'ascend'],
    },
    // --- Name
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: FormProps, b: FormProps) => a.name.localeCompare(b.name),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: FormProps) => (
        <Typography.Text style={{ cursor: 'pointer' }} onClick={() => navigator(`${ROUTE_PATHS.SUBMIT_FORM_LIST_BY_FORM}?id=${data.id}`)}>
          {data.name}
        </Typography.Text>
      )
    },
    // --- submitedCount
    {
      title: t('system.submitedCount'),
      dataIndex: 'submitedCount',
      key: 'submitedCount',
      sorter: {
        compare: (a: FormProps, b: FormProps) => Number(a.submitedCount) - Number(b.submitedCount),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: FormProps) => (
        <Typography.Text>
          {data.submitedCount}
        </Typography.Text>
      ),
    },
  ]), [navigator, t]);

  const tableData: FormProps[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.id,
      code: item.code,
      name: item.name,
      submitedCount: item.submitedCount,
    })) || []), [listData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.typeFormSubmit')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'code', 'name', 'submitedCount'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
    </>
  );
};

export default TypeFormManagement;
