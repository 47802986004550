import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, DatePicker, message, Row, Space, Spin, Typography
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PackageLogTable from './PackageLogsTable';

import { SimpleEditor } from 'common/components/Editor';
import ErrorText from 'common/components/ErrorText';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { StatusButtons, StatusPackageLabel } from 'common/components/StatusLabel';
import {
  createPackageService, getPackageByIdService, updatePackageService, updateStatusPackageService
} from 'common/services/extends/package';
import { EPackageStatus, EPackageType, TPackageRequest } from 'common/services/extends/package/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { useSchema } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

type TFormReturn = TPackageRequest;
const ignoreStatus = [13, 21];

const PackageDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  const { t } = useTranslation();
  const { packageSchema } = useSchema();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));

  const [status, setStatus] = useState<number>(1);
  const [isRefetch, setIsRefetch] = useState(false);

  const queryClient = useQueryClient();
  const queryKey = ['get-package-detail', idParams];
  const { data: packageRes, isLoading: getPackageLoading } = useQuery(
    queryKey,
    () => getPackageByIdService(idParams),
    {
      enabled: !!idParams,
      onSuccess(data) {
        if (data) {
          setStatus(data.packageData.status);
        }
      },
    }
  );

  const isDefaultPackage = packageRes?.packageData.type === EPackageType.Default;
  const isFreePackage = packageRes?.packageData.type === EPackageType.Free;

  const method = useForm<TFormReturn>({
    mode: 'onChange',
    defaultValues: {
      displayOrder: 0,
      name: '',
      accessTime: 0,
      validityPeriod: 0,
      price: 0,
      description1: '',
      description2: '',
      startDate: moment().toISOString(),
      endDate: undefined,
    },
    resolver: yupResolver(packageSchema),
  });

  const watchIsExpired = method.watch('endDate') && moment(method.watch('endDate')).isBefore(moment());

  const { isDirty } = method.formState;

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['create-package'],
    createPackageService,
    {
      onSuccess: () => {
        navigate(`${ROUTE_PATHS.PACKAGE_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['update-package'],
    updatePackageService,
    {
      onSuccess: () => {
        setIsRefetch(true);
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }

    }
  );

  const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(
    'updateNewsStatus',
    async (data: {
      id: number, statusId: EPackageStatus
    }) => updateStatusPackageService({ id: data.id, status: data.statusId }),
    {
      onSuccess: (_, params) => {
        setStatus(params.statusId);
        setIsRefetch(true);
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  const onSubmit = async (addingFn?: (id: number) => void) => {
    const isValid = method.trigger();
    if (!isValid || watchIsExpired) {
      return;
    }

    const formData = method.getValues();
    const {
      displayOrder,
      name,
      accessTime,
      validityPeriod,
      price,
      description1,
      description2,
      startDate,
      endDate
    } = formData;
    const requestData = {
      displayOrder,
      name,
      accessTime,
      validityPeriod,
      description1,
      description2,
      price,
      startDate: new Date(`${moment(startDate).format('YYYY-MM-DD')} 00:00:00`).toISOString(),
      endDate: endDate ? new Date(`${moment(endDate).format('YYYY-MM-DD')} 23:59:59`).toISOString() : null
    };
    if (idParams) {
      updateMutate({
        id: idParams,
        data: requestData
      });
    } else {
      createMutate(requestData, {
        onSuccess: (data) => addingFn && addingFn(data.id)
      });
    }
  };

  const handleChangeStatusAndSave = async (stat: number) => {
    if (watchIsExpired) {
      return;
    }

    if (!idParams) {
      await onSubmit((id) => {
        updateStatusMutate({ id, statusId: stat });
      });
    } else {
      if (isDirty) {
        await onSubmit();
      }
      updateStatusMutate({ id: idParams, statusId: stat });
    }
  };

  const submitForm = async () => {
    await onSubmit();
    queryClient.invalidateQueries(queryKey);
  };

  useEffect(() => {
    if (packageRes) {
      method.reset({
        displayOrder: packageRes.packageData.displayOrder,
        name: packageRes.packageData.name,
        accessTime: packageRes.packageData.accessTime,
        validityPeriod: packageRes.packageData.validityPeriod,
        description1: packageRes.packageData.description1,
        description2: packageRes.packageData.description2 || '',
        price: packageRes.packageData.price,
        startDate: moment(packageRes.packageData.startDate).toISOString(),
        endDate: packageRes.packageData.endDate
          ? moment(packageRes.packageData.endDate).toISOString() : undefined,
      });
    }
  }, [packageRes, method]);

  useEffect(() => {
    if (watchIsExpired) {
      method.setError('endDate', { message: t('package.expired') });
    }
  }, [method, t, watchIsExpired]);

  return (
    <FormProvider<TFormReturn> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('package.editPackage') : t('package.createPackage')}
        rightHeader={(
          <Space size={16}>
            <StatusButtons
              loading={updateStatusLoading}
              status={status}
              isApprove={roleOther.includes(roles.PACKAGE_APPROVED)}
              handleChangeStatus={(stat) => {
                handleChangeStatusAndSave(stat);
              }}
            />
            {idParams && status !== 21 ? (
              <Button
                disabled={!roleUpdate}
                loading={updateStatusLoading}
                className="btn-inactive"
                onClick={() => handleChangeStatusAndSave(EPackageStatus.Inactive)}
              >
                <SaveOutlined />
                {t('system.inactive')}
              </Button>
            ) : null}
            {!ignoreStatus.includes(status) && (
              <Button
                type="primary"
                disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
                loading={updateLoading || createLoading}
                onClick={method.handleSubmit(submitForm)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin spinning={getPackageLoading}>
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text strong>
                      {t('aiServer.name')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="name"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          name="name"
                          placeholder="Nhập Thông tin"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <Typography.Text strong>
                      {t('package.price')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="price"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          type="number"
                          name="price"
                          placeholder="Nhập giá"
                          value={value}
                          onChange={onChange}
                          disabled={isFreePackage}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.accessTime')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="accessTime"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          type="number"
                          name="accessTime"
                          placeholder="Nhập thời gian truy cập"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          disabled={isDefaultPackage}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.validityPeriod')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="validityPeriod"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          type="number"
                          name="validityPeriod"
                          placeholder="Nhập thời hạn hiệu lực"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          disabled={isDefaultPackage}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.startDate')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="startDate"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <DatePicker
                            className="u-mt-8"
                            size="large"
                            value={moment(value)}
                            disabledDate={(date) => (method.watch('endDate') ? date > moment(method.watch('endDate')) : false)}
                            onChange={onChange}
                            format="DD/MM/YYYY"
                            defaultValue={moment()}
                            allowClear={false}
                            style={{ width: '100%' }}
                            status={error?.message ? 'error' : undefined}
                          />
                          {
                            error && (
                              <ErrorText>
                                {error.message}
                              </ErrorText>
                            )
                          }
                        </>
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.endDate')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="endDate"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <DatePicker
                            className="u-mt-8"
                            size="large"
                            value={value ? moment(value) : undefined}
                            disabledDate={(date) => date < moment(method.watch('startDate')) || date < moment()}
                            onChange={onChange}
                            format="DD/MM/YYYY"
                            disabled={isDefaultPackage}
                            style={{ width: '100%' }}
                            status={error?.message ? 'error' : undefined}
                          />
                          {
                            error && (
                              <ErrorText>
                                {error.message}
                              </ErrorText>
                            )
                          }
                        </>
                      )}
                    />
                  </Col>
                  <Col span={24} className="u-mt-16">
                    <Typography.Text strong>
                      {t('newsDetail.displayOrder')}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="displayOrder"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          type="number"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          placeholder="Input display order"
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={24} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.description1')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="description1"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextArea
                            className="u-mt-8"
                            name="description1"
                            placeholder="Nhập mô tả"
                            value={value}
                            onChange={onChange}
                            disabled={isDefaultPackage}
                            size="large"
                          />
                          {error && <div className="u-mt-8 u-text-red-500">{error?.message}</div>}
                        </>
                      )}
                    />
                  </Col>
                  <Col span={24} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.description2')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="description2"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <SimpleEditor
                            disabled={isDefaultPackage}
                            value={value}
                            placeholder="Nhập mô tả"
                            handleChange={(_event: any, editor: any) => {
                              const data = editor.getData();
                              onChange(data);
                            }}
                          />
                          {error && <div className="u-mt-8 u-text-red-500">{error?.message}</div>}
                        </>
                      )}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <div className="u-mb-16">
                {status && <StatusPackageLabel status={status} bigger />}
              </div>
              <ManagementInfo
                createdDate={packageRes ? moment(packageRes?.packageData?.createdAt).fromNow() : ''}
                createdBy={packageRes?.creator?.name || ''}
                lastUpdated={packageRes ? moment(packageRes?.packageData?.updatedAt).fromNow() : ''}
                lastUpdatedBy={packageRes?.updater?.name || ''}
              />
            </Col>
            {idParams && getPermission(roleOther, roles.PACKAGE_LOG_INDEX) ? (
              <Col className="u-mt-16">
                <Card title={<Typography.Title level={4}>{t('package.logs')}</Typography.Title>}>
                  <PackageLogTable
                    refetch={isRefetch}
                    handleAfterRefetch={() => setIsRefetch(false)}
                    roleIndex={getPermission(roleOther, roles.PACKAGE_LOG_INDEX)}
                  />
                </Card>
              </Col>
            ) : null}
          </Row>
        </Spin>
      </div>
    </FormProvider>
  );
};

export default PackageDetail;
