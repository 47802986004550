import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Typography,
  message
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { DropdownElementLocation } from 'common/components/DropdownType/DropdownLocation';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  getDetailCustomerService, updateCustomerService,
} from 'common/services/extends/customer';
import { Gender, Status, UpdateCustomerParams } from 'common/services/extends/customer/types';
import { genderType, statusType } from 'common/utils/constant';
import { useSchema } from 'common/utils/schemas';

type MainDataTypes = {
  phone: string;
  name: string;
  address: string;
  email: string;
  status: Status;
  birthYear: number;
  password: string;
  countryCode: OptionType;
  provinceCode: OptionType;
  gender: Gender;
  serverCode: string;
  serverLink: string;
  facebookId: string;
  googleId: string
};

const EditCustomer: React.FC<ActiveRoles> = ({ roleUpdate }) => {
  const { customerDataSchema } = useSchema();

  const mainMethod = useForm<MainDataTypes>({
    resolver: yupResolver(customerDataSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      address: '',
      status: 7,
      phone: '',
      birthYear: 1970,
      password: '',
      countryCode: undefined,
      provinceCode: undefined,
      gender: 1,
      email: '',
      serverCode: '',
      serverLink: '',
      facebookId: '',
      googleId: ''
    }
  });

  const queryClient = useQueryClient();

  /* Selectors */

  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* Queries */
  const queryKeyDetail = ['getDetailCustomer', idParam];
  const { data: detailStaticBlock } = useQuery(
    queryKeyDetail,
    () => {
      if (idParam) {
        return getDetailCustomerService(idParam);
      }
      return undefined;
    }
  );

  const { mutate: updateCustomerMutate, isLoading: updateLoading } = useMutation(
    'updateCustomer',
    updateCustomerService,
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKeyDetail);
      },
      onError: (error: any) => {
        const errors = error as Array<ErrorAPIResponseTypes>;

        const messageErr: string[] = [];

        if (errors && errors.length > 0) {
          errors.forEach((item) => {
            messageErr.push(item?.message || '');
          });
        }

        message.error(messageErr.join(', '));
      }
    }
  );

  /* Effects */
  useEffect(() => {
    if (detailStaticBlock) {
      mainMethod.reset({
        phone: detailStaticBlock.customerData.phone,
        name: detailStaticBlock.customerData.name,
        address: detailStaticBlock.customerData.address,
        status: detailStaticBlock.customerData.status,
        birthYear: detailStaticBlock.customerData.birthYear,
        password: undefined,
        countryCode: {
          label: detailStaticBlock.countryData?.name || '',
          value: detailStaticBlock.countryData?.code || ''
        },
        provinceCode: {
          label: detailStaticBlock.provinceData?.name || '',
          value: detailStaticBlock.provinceData?.code || ''
        },
        gender: detailStaticBlock.customerData.gender,
        email: detailStaticBlock.customerData.email,
        serverCode: detailStaticBlock.customerData.serverCode || '',
        serverLink: detailStaticBlock.customerData.serverLink || '',
        facebookId: detailStaticBlock.customerData.facebookId || '',
        googleId: detailStaticBlock.customerData.googleId || ''
      });
    } else {
      mainMethod.reset();
    }
  }, [detailStaticBlock, mainMethod]);

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: UpdateCustomerParams = {
      name: mainData.name,
      birthYear: mainData.birthYear,
      gender: mainData.gender,
      phone: mainData.phone,
      address: mainData.address,
      countryCode: mainData.countryCode.value?.toString() || '',
      provinceCode: mainData.provinceCode.value?.toString() || '',
      ...(mainData.password ? { password: mainData.password } : {}),
    };

    try {
      if (idParam) {
        updateCustomerMutate({
          id: idParam,
          ...dataSubmit,
        });
      }
    } catch (error: any) {
      message.error(t('message.updateError'));
    } finally {
      mainMethod.reset();
    }
  };

  const codeCountry = mainMethod.watch('countryCode');

  /* Render */
  return (
    <div className="p-editStaticBlock">
      <HeaderPage
        fixed
        title={t('customer.editCustomerTitle')}
        rightHeader={(
          <Space size={16}>
            <Button
              disabled={!roleUpdate}
              type="primary"
              onClick={handleSubmit}
              loading={updateLoading}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            <Card
              type="inner"
            >
              <div className="site-card-border-less-wrapper">
                <Space direction="vertical" size={12} style={{ width: '100%' }}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input">
                        <Typography.Text strong>
                          {t('system.name')}
                          {' '}
                        </Typography.Text>
                        <Controller
                          name="name"
                          control={mainMethod.control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="title"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input">
                        <Typography.Text strong>
                          {t('customer.birthYear')}
                          {' '}
                        </Typography.Text>
                        <Controller
                          name="birthYear"
                          control={mainMethod.control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error }
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="title"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('system.phone')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="phone"
                        control={mainMethod.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="title"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('system.address')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="address"
                        control={mainMethod.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="title"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.password')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="password"
                        control={mainMethod.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="title"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.country')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="countryCode"
                        control={mainMethod.control}
                        render={({ field }) => (
                          <DropdownElementLocation
                            placeholder="---"
                            locale="vi"
                            value={field.value}
                            onChange={field.onChange}
                            size="large"
                            isGetOption
                            isCountry
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.stateProvince')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="provinceCode"
                        control={mainMethod.control}
                        render={({ field }) => (
                          <DropdownElementLocation
                            placeholder="---"
                            locale="vi"
                            value={field.value}
                            onChange={field.onChange}
                            size="large"
                            isProvince
                            isGetOption
                            codeCountry={codeCountry?.value?.toString() || ''}
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('system.gender')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="gender"
                        control={mainMethod.control}
                        render={({
                          field,
                          fieldState: { error }
                        }) => (
                          <>
                            <Select
                              className="u-mt-8"
                              size="large"
                              style={{ width: '100%' }}
                              placeholder="---"
                              onChange={(value) => {
                                field.onChange(value);
                              }}
                              value={field.value}
                              status={error?.message ? 'error' : undefined}
                            >
                              {
                                genderType.map((val, idx) => (
                                  <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                                    {val.label}
                                  </Select.Option>
                                ))
                              }
                            </Select>
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.serverCode')}
                        {': '}
                      </Typography.Text>
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {mainMethod.getValues('serverCode')}
                        </Typography.Text>
                      </span>
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('system.email')}
                        {': '}
                      </Typography.Text>
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {mainMethod.getValues('email')}
                        </Typography.Text>
                      </span>
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.serverLink')}
                        {': '}
                      </Typography.Text>
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {mainMethod.getValues('serverLink')}
                        </Typography.Text>
                      </span>
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.facebookId')}
                        {': '}
                      </Typography.Text>
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {mainMethod.getValues('facebookId')}
                        </Typography.Text>
                      </span>
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('customer.googleId')}
                        {': '}
                      </Typography.Text>
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {mainMethod.getValues('googleId')}
                        </Typography.Text>
                      </span>
                    </Col>
                  </Row>
                </Space>
              </div>
            </Card>

          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <div className="u-mb-16">
              <div style={{
                padding: '1rem',
                backgroundColor: detailStaticBlock?.customerData.status === 1 ? '#eafbe7' : '#f2e0ce'
              }}
              >
                {detailStaticBlock?.customerData.status
                  && (
                    <Typography.Text
                      style={{
                        color: detailStaticBlock?.customerData.status === 1 ? '#2f6846' : '#d9822f',
                        fontSize: '14px'
                      }}
                    >
                      {`Status: ${(statusType.find((item) => {
                        const status = detailStaticBlock?.customerData.status;
                        return item.value === status;
                      }))?.label}`}
                    </Typography.Text>
                  )}
              </div>
            </div>
            <ManagementInfo
              createdDate={detailStaticBlock ? moment(detailStaticBlock?.customerData?.createdAt).fromNow() : ''}
              createdBy={detailStaticBlock?.customerData?.name || ''}
              lastUpdated={detailStaticBlock ? moment(detailStaticBlock?.customerData?.updatedAt).fromNow() : ''}
              lastUpdatedBy={detailStaticBlock?.customerData?.name || ''}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditCustomer;
