import {
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { getCustomerUsageHistoriesService } from 'common/services/extends/order';
import { formatDateTime, formatTime } from 'common/utils/functions';

export type TableDataTypes = {
  id: number
  name: string
  email: string
  phone: string
  date: string
  timeStart: string
  timeStop: string,
  timeCurrent: string,
  timeUsed: string,
  timeLeft: string,
  serverCode: string,
  serverName: string,
  serverApiKey: string,
  serverIsOnline: boolean,
};

const CustomerUsageHistories: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParam = searchParams.get('id');

  /* Selectors */
  const {
    defaultPageSize,
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const queryKey = ['customerUsageHistory-list', idParam, keyword, currentPage, currentView];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => {
      if (idParam) {
        return getCustomerUsageHistoriesService(idParam, {
          keyword, page: currentPage, limit: currentView
        });
      }
      return undefined;
    },
    {
      keepPreviousData: true
    }
  );

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      id: val.customerUsageHistoryData.id,
      name: val.customerData.name,
      email: val.customerData.email,
      phone: val.customerData.phone,
      date: val.customerUsageHistoryData.date,
      timeStart: val.customerUsageHistoryData.timeStart,
      timeStop: val.customerUsageHistoryData.timeStop,
      timeCurrent: formatTime(val.customerUsageHistoryData.timeCurrent),
      timeUsed: formatTime(val.customerUsageHistoryData.timeUsed),
      timeLeft: formatTime(val.customerUsageHistoryData.timeLeft),
      serverCode: val.customerUsageHistoryData.serverCode,
      serverName: val.customerUsageHistoryData.serverName,
      serverApiKey: val.customerUsageHistoryData.serverApiKey,
      serverIsOnline: val.customerUsageHistoryData.serverIsOnline,
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name?.localeCompare(b.name),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.email?.localeCompare(b.email)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.email}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.phone?.localeCompare(b.phone)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.date'),
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.date);
          const bDate = new Date(b.date);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.date)}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.serverCode'),
      dataIndex: 'serverCode',
      key: 'serverCode',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.serverCode?.localeCompare(b.serverCode)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.serverCode}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.serverName'),
      dataIndex: 'serverName',
      key: 'serverName',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.serverName}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.serverApiKey'),
      dataIndex: 'serverApiKey',
      key: 'serverApiKey',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.serverApiKey}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.serverIsOnline'),
      dataIndex: 'serverIsOnline',
      key: 'serverIsOnline',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.serverIsOnline ? t('aiServer.online') : t('aiServer.offline')}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.timeStart'),
      dataIndex: 'timeStart',
      key: 'timeStart',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.timeStart)}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.timeStop'),
      dataIndex: 'timeStop',
      key: 'timeStop',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.timeStop)}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.timeCurrent'),
      dataIndex: 'timeCurrent',
      key: 'timeCurrent',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.timeCurrent}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.timeUsed'),
      dataIndex: 'timeUsed',
      key: 'timeUsed',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.timeUsed}
        </Typography.Text>
      ),
    },
    {
      title: t('customerUsageHistories.timeLeft'),
      dataIndex: 'timeLeft',
      key: 'timeLeft',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.timeLeft}
        </Typography.Text>
      ),
    }
  ];

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerUsageHistories')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          tableProps={{
            initShowColumns: [
              'id',
              'name',
              'email',
              'phone',
              'serverCode',
              'serverName',
              'serverApiKey',
              'serverIsOnline',
              'date',
              'timeStart',
              'timeStop',
              'timeCurrent',
              'timeUsed',
              'timeLeft'
            ],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listRes?.meta.total || 1,
            noBaseCol: true,
          }}
        />
      </div>
    </>
  );
};

export default CustomerUsageHistories;
