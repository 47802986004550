import { Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import useDebounce from 'common/hooks/useDebounce';
import useScrollInfinite from 'common/hooks/useScrollInfinite';
import { getCountriesService, getProvincesService } from 'common/services/location';
import { LocationDataTypes } from 'common/services/location/types';

export type DropdownElementLocationProps = {
  options?: OptionType[];
  name?: string;
  locale: string;
  isValueSlug?: boolean;
  placeholder?: string;
  size?: SizeType;
  value: any;
  onChange: (...event: any[]) => void;
  filterParams?: string;
  isGetOption?: boolean;
  hasAllOption?: boolean;
  isShowSearch?: boolean;
  multiple?: {
    allowClear?: boolean;
    defaultValue?: any;
  },
  error?: string;
  ids?: string;
  slugs?: string;
  isCountry?: boolean;
  isProvince?: boolean;
  codeCountry?: string;
};

export const DropdownElementLocation: React.FC<DropdownElementLocationProps> = ({
  options,
  size,
  locale,
  isValueSlug,
  placeholder,
  isShowSearch,
  value,
  onChange,
  filterParams,
  hasAllOption,
  isGetOption,
  multiple,
  error,
  ids,
  slugs,
  isCountry,
  isProvince,
  codeCountry,
}) => {
  const [txtSearch, setTxtSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');
  const [optionsData, setOptionsData] = useState<OptionType[]>(options || []);

  useDebounce(() => {
    setSearchDebounce(txtSearch);
  }, 500, [txtSearch]);

  const {
    data: dropdownList,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    [`dropdown-location-${isCountry ? 'country' : 'province'}`, {
      locale, searchDebounce, ids, slugs, codeCountry
    }],
    ({ pageParam = 1 }) => {
      if (isCountry) {
        return getCountriesService({
          keyword: searchDebounce,
          limit: 10,
          page: pageParam,
        });
      }
      if (isProvince) {
        return getProvincesService({
          keyword: searchDebounce,
          limit: 10,
          page: pageParam,
          countryCode: codeCountry
        });
      }
      return undefined;
    },
    {
      getNextPageParam:
        (lastPage) => lastPage && (lastPage.meta.page + 1 <= lastPage.meta.totalPages
          ? lastPage.meta.page + 1
          : undefined),
    },
  );

  const { setNode } = useScrollInfinite(() => {
    fetchNextPage();
  });

  useEffect(() => {
    if (dropdownList) {
      const data = dropdownList.pages.reduce(
        (prev: OptionType[], current) => [...prev,
        ...(current?.data || []).map((val: LocationDataTypes) => {
          if (isCountry) {
            return {
              label: val.countryData?.name || '',
              value: isValueSlug ? val.countryData?.slug : val.countryData?.code,
            };
          }
          if (isProvince) {
            return {
              label: val.provinceData?.name || '',
              value: isValueSlug ? val.provinceData?.slug : val.provinceData?.code,
            };
          }
          return {
            label: '',
            value: '',
          };
        }).filter((val: OptionType) => (
          (filterParams ? val?.value?.toString() !== filterParams : true)))],
        []
      );
      setOptionsData((hasAllOption && !searchDebounce) ? [{ label: 'Tất cả', value: null }, ...data] : data);
    }
  }, [dropdownList, filterParams, hasAllOption, isValueSlug,
    searchDebounce, isCountry, isProvince]);

  useEffect(() => {
    if (options) {
      setOptionsData(options.filter((
        item
      ) => item.label.toLowerCase().includes(searchDebounce.toLowerCase())));
    }
  }, [options, searchDebounce]);

  return (
    <>
      <Select
        className="u-mt-8"
        size={size}
        style={{ width: '100%' }}
        placeholder={placeholder}
        value={value}
        onChange={(val) => {
          if (isGetOption) {
            onChange(optionsData.find((item) => item.value === val));
          } else {
            onChange(val);
          }
          if (multiple) {
            setTxtSearch('');
          }
        }}
        {...multiple && {
          mode: 'multiple',
          allowClear: multiple.allowClear,
          defaultValue: multiple.defaultValue,
        }}
        dropdownMatchSelectWidth={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultActiveFirstOption={false}
        showSearch={isShowSearch}
        filterOption={false}
        onSearch={(val) => isShowSearch && setTxtSearch(val)}
        onClear={() => setTxtSearch('')}
        allowClear
        loading={isLoading}
      >
        {
          optionsData.map(
            (option, idx) => (idx === optionsData.length - 3 ? (
              <Select.Option label={option.label} value={option.value} key={`option-${idx.toString()}`}>
                <div ref={(ref) => setNode(ref)}>{option.label}</div>
              </Select.Option>
            ) : (
              <Select.Option label={option.label} value={option.value} key={`option-${idx.toString()}`}>
                {option.label}
              </Select.Option>
            ))
          )
        }
      </Select>
      {error && (
        <span
          className="a-input_errorMessage"
        >
          {error}
        </span>
      )}
    </>
  );
};
