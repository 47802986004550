/* eslint-disable no-param-reassign */
import { CloseOutlined } from '@ant-design/icons';
import {
  Checkbox, Space, Typography,
} from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  RolePermissionModuleForm,
  RolePermissionColumnType,
  RolePermissionProps,
  RolePermissionTableRow,
  RolePermissionWithModuleList,
} from './types';

import { PermissionData } from 'common/services/roles/types';
import { removeItem } from 'common/utils/functions';

export const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  return (
    <div className="ant-select-selection-item">
      <Space>
        <Checkbox
          checked
        />
        <Typography.Text>
          {label}
        </Typography.Text>
        {closable && (
          <div className="ant-select-selection-item-remove" onClick={onClose}>
            <CloseOutlined />
          </div>
        )}
      </Space>
    </div>
  );
};

const handleCheckPermissionRow = (
  currentPermit?: RolePermissionTableRow,
  generalPermit?: RolePermissionTableRow,
) => {
  let rowCheckboxVal = {
    checked: false,
    indeterminate: false,
  };

  if (!currentPermit || !generalPermit) {
    return rowCheckboxVal;
  }

  //* Function for checking if there's any selected option in currentPermit
  //* -> if true, set indeterminate: true
  const handleCheckIsSelectAny = () => {
    const isNotSelectAny = Object.values(currentPermit).every((ele) => {
      if (Array.isArray(ele)) {
        if (ele.length) {
          return false;
        }
      } else if (ele) {
        return false;
      }
      return true;
    });

    if (!isNotSelectAny) {
      rowCheckboxVal = {
        ...rowCheckboxVal,
        indeterminate: true,
      };
    }
  };

  //* Function for checking if all permission in currentPermit = generalPermit
  //* -> if false, return false and call handleCheckIsSelectAny to check any selected
  //* -> else return true
  const isCheckAll = Object.entries(generalPermit).every(([permission, val]) => {
    switch (permission) {
      case 'index':
      case 'show':
      case 'store':
      case 'update':
      case 'destroy': {
        if (val !== currentPermit[permission]) {
          handleCheckIsSelectAny();
          return false;
        }

        rowCheckboxVal = {
          ...rowCheckboxVal,
          indeterminate: true,
        };
        return true;
      }
      case 'others': {
        if (currentPermit[permission]) {
          if (Array.isArray(val) && (val.length !== currentPermit[permission]?.length)) {
            handleCheckIsSelectAny();
            return false;
          }
        }
        return true;
      }
      default:
        return false;
    }
  });

  if (isCheckAll) {
    //* This mean all permission have been check
    //* -> Checkbox will be checked instead of indeterminate
    rowCheckboxVal = {
      checked: true,
      indeterminate: false,
    };
  }

  return rowCheckboxVal;
};

export const RowCheckbox: React.FC<{
  module: string,
  moduleForm: RolePermissionModuleForm;
}> = ({ module, moduleForm }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* React-hook-form */
  const methods = useFormContext();
  const modulePermissionWatch = useWatch({
    control: methods.control,
    name: `permissions[${module}]`,
  });

  /* Data */
  const checkboxData = useMemo(() => handleCheckPermissionRow(
    modulePermissionWatch,
    moduleForm[module],
  ), [module, moduleForm, modulePermissionWatch]);

  return (
    <Space align="center">
      <Checkbox
        indeterminate={checkboxData.indeterminate}
        checked={checkboxData.checked}
        onChange={(e) => {
          //* This is select all row

          if (e.target.checked) {
            //* Select all row
            Object.entries(moduleForm[module]).forEach(([permission, val]) => {
              const columnPermission = methods.getValues(`permissionWithModuleList[${permission}]`);
              //* Add all permissions of module from generalFrom to currentForm
              methods.setValue(`permissions[${module}][${permission}]`, val);

              switch (permission) {
                case 'index':
                case 'show':
                case 'store':
                case 'update':
                case 'destroy': {
                  //* Check if permissionWithModuleList already have this module
                  //* -> if none add it
                  //* -> else skip it
                  const index = columnPermission.indexOf(module);
                  if (!(index > -1)) {
                    methods.setValue(`permissionWithModuleList[${permission}]`, [...columnPermission, module]);
                  }
                  break;
                }
                case 'others': {
                  if (Array.isArray(val)) {
                    //* Filter duplicate modules then add to permissionWithModuleList
                    const newList = [...val].filter(
                      (ele) => ![...(columnPermission || [])].includes(ele)
                    );

                    methods.setValue(`permissionWithModuleList[${permission}]`, [...columnPermission, ...newList]);
                  }
                  break;
                }
                default:
                  break;
              }
            });
          } else {
            //* Deselect all row
            Object.entries(moduleForm[module]).forEach(([permission, val]) => {
              const columnPermission = methods.getValues(`permissionWithModuleList[${permission}]`);

              switch (permission) {
                case 'index':
                case 'show':
                case 'store':
                case 'update':
                case 'destroy': {
                  //* Set all these permission of this module in currentForm to false
                  methods.setValue(`permissions[${module}][${permission}]`, false);

                  //* Remove this module in permissionWithModuleList
                  const newArray = removeItem(columnPermission, module);
                  methods.setValue(`permissionWithModuleList[${permission}]`, newArray);
                  break;
                }
                case 'others': {
                  if (Array.isArray(val)) {
                    //* Set others permissions of this module in currentForm to empty
                    methods.setValue(`permissions[${module}][${permission}]`, []);

                    //* Remove all of others permissions of this module in permissionWithModuleList
                    const newList = [...columnPermission].filter((ele) => !val?.includes(ele));
                    methods.setValue(`permissionWithModuleList[${permission}]`, newList);
                  }
                  break;
                }
                default:
                  break;
              }
            });
          }
        }}
      />
      <Typography.Text>
        {t(`role.${module}`)}
      </Typography.Text>
    </Space>
  );
};

interface ColumnHeaderTitleProps {
  action: keyof RolePermissionTableRow,
  title: string;
  permissionWithModuleList: RolePermissionWithModuleList;
}

export const ColumnHeaderTitle: React.FC<ColumnHeaderTitleProps> = ({
  action,
  title,
  permissionWithModuleList,
}) => {
  /* React-hook-form */
  const methods = useFormContext();

  return (
    <Controller
      name={`permissionWithModuleList[${action}]`}
      defaultValue={[]}
      render={({
        field: { value, onChange },
      }) => (
        <Space direction="vertical">
          <Typography.Text>
            {title}
          </Typography.Text>
          <Checkbox
            indeterminate={!!value.length && value.length < permissionWithModuleList[action].length}
            checked={value.length === permissionWithModuleList[action].length}
            onChange={(e) => {
              //* This is select all column

              if (e.target.checked) {
                //* Select all column
                //* -> Add all modules list of this permission from general to current
                onChange(permissionWithModuleList[action]);
              } else {
                //* Deselect all
                //* -> Set this module list of this permission to empty
                onChange([]);
              }

              //* Update value of this permission for each module has it in currentForm
              switch (action) {
                case 'index':
                case 'show':
                case 'store':
                case 'update':
                case 'destroy': {
                  permissionWithModuleList[action].forEach((item) => {
                    methods.setValue(`permissions[${item}][${action}]`, e.target.checked);
                  });
                  break;
                }
                case 'others': {
                  if (e.target.checked) {
                    //* Select all column
                    //* -> Filter duplicate permission and
                    //* add to others list of this module in currentForm
                    permissionWithModuleList[action].forEach((item) => {
                      const [module] = item.split('.');

                      const othersListInModuleForm = methods.getValues(`permissions[${module}][${action}]`);
                      const index = othersListInModuleForm.indexOf(item);
                      if (!(index > -1)) {
                        methods.setValue(`permissions[${module}][${action}]`, [...othersListInModuleForm, item]);
                      }
                    });
                  } else {
                    //* Deselect all
                    //* -> Set others permissions list  of this module to empty
                    permissionWithModuleList[action].forEach((item) => {
                      const [module] = item.split('.');
                      methods.setValue(`permissions[${module}][${action}]`, []);
                    });
                  }
                  break;
                }
                default:
                  break;
              }
            }}
          />
        </Space>
      )}
    />
  );
};

export const getPermissionColumnObj = (
  action: keyof RolePermissionTableRow,
  title: string,
  permissionWithModuleList: RolePermissionWithModuleList,
):
  RolePermissionColumnType => ({
    title: (
      <ColumnHeaderTitle
        action={action}
        title={title}
        permissionWithModuleList={permissionWithModuleList}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: action,
    key: action,
    render: (_name, _data) => {
      if (_data[action]) {
        return (
          <Controller
            name={`permissionWithModuleList[${action}]`}
            defaultValue={false}
            render={({
              field: { value: permissionModulesValue, onChange: permissionModulesOnChange },
            }) => (
              <Controller
                name={`permissions[${_data.module}][${action}]`}
                defaultValue={false}
                render={({
                  field: { value, onChange },
                }) => (
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      //* This is select one of index | show |store | update | destroy permission

                      //* Update value in currentForm
                      onChange(e);

                      //* Update value about this module in permissionWithModuleList
                      if (e.target.checked) {
                        permissionModulesOnChange([...permissionModulesValue, _data.module]);
                      } else {
                        const newArray = removeItem(permissionModulesValue, _data.module);
                        permissionModulesOnChange(newArray);
                      }
                    }}
                    style={{
                      height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}
                  />
                )}
              />
            )}
          />
        );
      }
      return null;
    },
  });

export const defaultPermissionWithModuleListVal: RolePermissionWithModuleList = {
  index: [],
  show: [],
  store: [],
  update: [],
  destroy: [],
  others: [],
};

function getValueWithType<T extends keyof RolePermissionTableRow>(
  data: RolePermissionTableRow,
  key: T,
) {
  return data[key];
}

export const convertPermissionData = (permissions: RolePermissionModuleForm) => {
  let array: string[] = [];
  Object.entries(permissions).forEach(([key, value]) => {
    Object.entries(value).forEach(([keyData, valueData]) => {
      if (keyData === 'others') {
        const othersArr = getValueWithType(value, keyData);
        array = [...array, ...(othersArr || [])];
      } else if (valueData) {
        array = [...array, `${key}.${keyData}`];
      }
    });
  });
  return array;
};

// @params:
// {
//   "page": [
//     "page.index",
//     "page.store",
//     "page.show",
//     "page.update",
//     "page.destroy"
//   ],
//   ...
// }

// @return:
// tableData: [
//   {
//     module: string;
//     index?: boolean;
//     show?: boolean;
//     store?: boolean;
//     update?: boolean;
//     destroy?: boolean;
//     others?: string[];
//   },
//   ...
// ]
// moduleForm: {
//   [x: string]: {
//     index?: boolean;
//     show?: boolean;
//     store?: boolean;
//     update?: boolean;
//     destroy?: boolean;
//     others?: string[];
//   },
//   ...
// }
// permissionWithModuleList: {
//    [x (index | show |store | update | destroy): string]: string[]
//   ...
// }

export const convertPermissionAllDataTable = (permissionData: PermissionData) => {
  const arrData: RolePermissionProps[] = [];
  let formData: RolePermissionModuleForm = {};
  let permissionsObj: RolePermissionWithModuleList = { ...defaultPermissionWithModuleListVal };

  Object.entries(permissionData).forEach(([module, modulePermissions]) => {
    const permissions = modulePermissions.map((ele) => ele.split('.')[1]);
    const permissionRender = permissions.reduce((prevObj: RolePermissionTableRow, permission) => {
      switch (permission) {
        case 'index':
        case 'show':
        case 'store':
        case 'update':
        case 'destroy': {
          permissionsObj = {
            ...permissionsObj,
            [permission]: [...(permissionsObj[permission] || []), module],
          };
          return {
            ...prevObj,
            [permission]: true,
          };
        }
        default:
          permissionsObj = {
            ...permissionsObj,
            others: [...(permissionsObj.others || []), `${module}.${permission}`],
          };
          return {
            ...prevObj,
            others: [...(prevObj.others || []), `${module}.${permission}`],
          };
      }
    }, {
      others: [],
    });
    arrData.push({
      module,
      ...permissionRender,
    });

    formData = {
      ...formData,
      [module]: { ...permissionRender },
    };
  });

  return {
    tableData: arrData.sort((a, b) => Object.keys(b).length - Object.keys(a).length),
    moduleForm: formData,
    permissionWithModuleList: permissionsObj,
  };
};

// @params:
// [
//   "page.index",
//   "page.store",
//    ...
// ]

// @return:
// {
//   [x: string]: {
//     index?: boolean;
//     show?: boolean;
//     store?: boolean;
//     update?: boolean;
//     destroy?: boolean;
//     others?: string[];
//   },
//   ...
// }

export const convertPermissionDataForm = (permissions: string[]) => {
  const permissionRender = permissions.reduce((prevObj: RolePermissionModuleForm, currVal) => {
    const [module, permission] = currVal.split('.');

    const newModuleData: RolePermissionTableRow = {
      others: [...(prevObj?.[module]?.others || [])],
    };

    switch (permission) {
      case 'index':
      case 'show':
      case 'store':
      case 'update':
      case 'destroy': {
        Object.assign(newModuleData, {
          [permission]: true,
        });
        break;
      }
      default: {
        Object.assign(newModuleData, {
          others: [...(newModuleData.others || []), currVal],
        });
        break;
      }
    }

    return Object.assign(prevObj, {
      [module]: {
        ...prevObj[module],
        ...newModuleData
      }
    });
  }, {});
  return permissionRender;
};

// @params:
// {
//   "page": [
//     "page.index",
//     "page.store",
//     "page.show",
//     "page.update",
//     "page.destroy"
//   ],
//   ...
// }

// @return:
// {
//    [x (index | show |store | update | destroy): string]: string[]
//   ...
// }

export const convertPermissionWithModuleList = (permissions: string[]) => {
  const permissionsObj = permissions.reduce((prevVal: RolePermissionWithModuleList, currVal) => {
    const [module, permission] = currVal.split('.');

    switch (permission) {
      case 'index':
      case 'show':
      case 'store':
      case 'update':
      case 'destroy': {
        return {
          ...prevVal,
          [permission]: [...prevVal[permission], module],
        };
      }
      default:
        return {
          ...prevVal,
          others: [...prevVal.others, `${module}.${permission}`],
        };
    }
  }, { ...defaultPermissionWithModuleListVal });

  return permissionsObj;
};
