import {
  Checkbox,
  Modal, Space, Typography
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormDetailType, FormFieldEnum, FormFieldType } from '../types';

import { DropdownField } from './RenderFormElement';
import RenderFormField, { RenderFormFieldRef } from './RenderFormField';

interface FormFieldModalProps {
  open: boolean;
  defaultForm?: FormFieldType;
  handleClose: () => void;
  handleAddField: (data: FormFieldType) => void;
  handleUpdateField: (data: FormFieldType) => void;
  method: UseFormReturn<FormDetailType, any>;
  index?: number;
}

const FormFieldModal: React.FC<FormFieldModalProps> = ({
  open,
  defaultForm,
  handleClose,
  handleAddField,
  handleUpdateField,
  method,
  index,
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [fieldType, setFieldType] = useState<FormFieldEnum>();
  const [showInList, setShowInList] = useState<boolean>(false);

  /* Refs */
  const formRef = useRef<RenderFormFieldRef>(null);

  /* Functions */
  const handleSubmitForm = async () => {
    const formData = await formRef.current?.handleSubmit();

    const formDataItem = method.getValues();

    if (formData && fieldType) {
      if (defaultForm) {
        //* Case update
        handleUpdateField({
          type: fieldType,
          data: formData,
          isShowInList: index && formDataItem.fields
            ? formDataItem.fields[index].isShowInList : false,
        });
      } else {
        //* Case create
        formRef.current?.handleReset();
        handleAddField({
          type: fieldType,
          data: formData,
          isShowInList: showInList
        });
      }
      handleClose();
    }
  };

  useEffect(() => {
    if (defaultForm?.type) {
      setFieldType(defaultForm.type);
    }
    setShowInList(defaultForm?.isShowInList || false);
  }, [defaultForm]);

  return (
    <Modal
      title={<Typography.Title level={3}>{t('formManagement.formModal')}</Typography.Title>}
      visible={open}
      centered
      onCancel={handleClose}
      width={800}
      maskClosable={false}
      onOk={handleSubmitForm}
      okText={defaultForm?.data ? t('system.update') : t('system.addNew')}
    >
      {/* Field type */}
      <Typography.Text strong>
        {t('formManagement.typeFormField')}
        {' '}
      </Typography.Text>
      <DropdownField
        placeholder={`${t('system.select')} ${t('formManagement.typeFormField')}`}
        value={fieldType}
        onChange={setFieldType}
      />
      {/* Render fields */}
      {fieldType && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <RenderFormField
            ref={formRef}
            fieldType={fieldType}
            defaultFormField={defaultForm?.data}
          />
          <div>
            {
              index || (index && index > -1) ? (
                <Controller
                  name={`fields.${index}.isShowInList`}
                  render={({
                    field: { value, onChange, ref },
                  }) => (
                    <Checkbox
                      ref={ref}
                      onChange={(e) => {
                        onChange(e);
                        setShowInList(e.target.checked);
                      }}
                      defaultChecked={showInList}
                      checked={value}
                    >
                      {t('formManagement.isShowInList')}
                    </Checkbox>
                  )}
                />
              ) : (
                <Checkbox
                  checked={showInList}
                  onChange={(e) => {
                    setShowInList(e.target.checked);
                  }}
                >
                  {t('formManagement.isShowInList')}
                </Checkbox>
              )
            }
          </div>
        </Space>
      )}
    </Modal>
  );
};

export default FormFieldModal;
