import { PlusOutlined } from '@ant-design/icons';
import {
  Button
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PromotionTable from './PromotionTable';

import HeaderPage from 'common/components/HeaderPage';
import { TPromotion } from 'common/services/extends/promotions/types';
import { ROUTE_PATHS } from 'common/utils/constant';

export type TableDataTypes = TPromotion['promotionProgramData'];

const PromotionManagement: React.FC<ActiveRoles> = ({
  roleIndex, roleCreate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.promotion')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => navigate(ROUTE_PATHS.PROMOTION_DETAIL)}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PromotionTable
          roleIndex={roleIndex}
        />
        <PromotionTable
          roleIndex={roleIndex}
          isInactiveRecords
        />
      </div>
    </>
  );
};

export default PromotionManagement;
