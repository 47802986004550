import axiosInstance from '../common/instance';

import { LocationDataTypes, ProvincesParamsTypes } from './types';

export const getCountriesService = async (
  params: BaseFilterParams
): Promise<APIPaginationResponse<LocationDataTypes[]>> => {
  const res = await axiosInstance.get('countries', { params });
  return res.data;
};

export const getProvincesService = async (
  params: ProvincesParamsTypes & BaseFilterParams
): Promise<APIPaginationResponse<LocationDataTypes[]>> => {
  const res = await axiosInstance.get('provinces', { params });
  return res.data;
};

export const Placeholder = '';
