import { EyeOutlined } from '@ant-design/icons';
import {
  Button, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import DetailModal from './logDetailModal';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusPackageLabel } from 'common/components/StatusLabel';
import { getPackageLogsService } from 'common/services/extends/package';
import { EPackageType, TPackageLogs } from 'common/services/extends/package/types';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';

export type TableDataTypes = TPackageLogs['packageLogData'];

const PackageLogTable: React.FC<Pick<ActiveRoles, 'roleIndex'> & { refetch?: boolean, handleAfterRefetch?(): void }> = ({
  roleIndex, refetch, handleAfterRefetch
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    if (searchParams.has('id')) delete paramsObj.id;
    return paramsObj;
  }, [searchParams]);
  const idParam = Number(searchParams.get('id'));
  const pageParam = searchParams.get('page');
  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);
  // const { roles: role } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [activeLog, setActiveLog] = useState<{
    id: number,
    packageId: number
  } | undefined>(undefined);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const queryKey = ['package-log-list', keyword, currentPage, currentView, selectedFilterList, refetch];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getPackageLogsService({
      id: idParam,
      params: {
        keyword, page: currentPage, limit: currentView, ...returnParamsFilter
      }
    }),
    {
      enabled: !!(idParam && roleIndex),
      onSuccess() {
        if (refetch) handleAfterRefetch?.();
      },
    }
  );

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      ...val.packageLogData
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name?.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    // --- Action
    {
      title: t('package.action'),
      dataIndex: 'packageAction',
      key: 'packageAction',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.action}
        </Typography.Text>
      ),
    },
    // --- Access time
    {
      title: t('package.accessTime'),
      dataIndex: 'accessTime',
      key: 'accessTime',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.accessTime)}
        </Typography.Text>
      ),
    },
    // --- validityPeriod time
    {
      title: t('package.validityPeriod'),
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.validityPeriod)}
        </Typography.Text>
      ),
    },
    // --- Price
    {
      title: t('package.price'),
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.price - b.price,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.price)}
        </Typography.Text>
      ),
    },
    // --- Price
    {
      title: t('system.type'),
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {EPackageType[data.type]}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_name: string, data: TableDataTypes) => (
        <StatusPackageLabel status={data.status} />
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      align: 'center',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!(roleIndex && idParam)}
            icon={<EyeOutlined />}
            onClick={() => setActiveLog({
              id: _data.id,
              packageId: idParam
            })}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('packages', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
      ...idParam && { id: idParam.toString() }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <PageTable
        noCheckbox
        handleSearch={setKeyword}
        isLoading={isLoading}
        roles={{
          roleCreate: false,
          roleDelete: false,
          roleUpdate: false
        }}
        tableProps={{
          initShowColumns: ['id', 'name', 'packageAction', 'accessTime', 'validityPeriod', 'price', 'type', 'status', 'createdAt', 'updatedAt', 'action'],
          columns,
          pageData: tableData || [],
          currentPage,
          pageSize: currentView,
          handleSetCurrentPage,
          handleSetCurrentView,
          total: listRes?.meta.total || 1,
          noBaseCol: true,
          filterFields,
        }}
        filtersDataTable={{
          handleFilter,
          selectedFilterList,
          handleDeleteFilter
        }}
      />
      <DetailModal
        openData={activeLog}
        handleClose={() => setActiveLog(undefined)}
      />
    </>
  );
};

export default PackageLogTable;
