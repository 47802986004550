import axiosInstance from '../../common/instance';

import {
  AIServerDetailType,
  AIServerType, ApiKeyParams, CreateAIParams, UpdateAIParams,
} from './types';

export const getAllAIService = async (
  params?: BaseFilterParams,
): Promise<APIPaginationResponse<AIServerType[]>> => {
  const res = await axiosInstance.get('ai-servers', { params });
  return res.data;
};

export const getByIdAIService = async (
  id: number,
): Promise<AIServerDetailType> => {
  const res = await axiosInstance.get(`ai-servers/${id}`);
  return res.data.data;
};

export const deleteAIService = async (
  ids: number[],
): Promise<void> => {
  await axiosInstance.delete('ai-servers', { data: { ids } });
};

export const createAIService = async (
  params: CreateAIParams,
): Promise<void> => {
  await axiosInstance.post('ai-servers', params);
};

export const updateAIService = async (
  params: UpdateAIParams,
): Promise<void> => {
  await axiosInstance.put(`ai-servers/${params.id}`, params);
};

export const updateApiKeyService = async (
  id: string,
  params: ApiKeyParams
): Promise<{ apiKey: string }> => {
  const response = await axiosInstance.post(`ai-servers/generate-api-key/${id}`, params);
  return response.data.data;
};

export const stopServerAIService = async (id: number): Promise<void> => {
  await axiosInstance.post(`ai-servers/stop-server/${id}`);
};
