import { PlusOutlined } from '@ant-design/icons';
import {
  Button
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PackageTable from './PackageTable';

import HeaderPage from 'common/components/HeaderPage';
import { TPackage } from 'common/services/extends/package/types';
import { ROUTE_PATHS } from 'common/utils/constant';

export type TableDataTypes = TPackage['packageData'];

const PackageManagement: React.FC<ActiveRoles> = ({
  roleIndex, roleCreate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.package')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => navigate(ROUTE_PATHS.PACKAGE_DETAIL)}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        {/* <PageTable
          noCheckbox
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          tableProps={{
            initShowColumns: ['id', 'name',
            'accessTime', 'validityPeriod',
            'price', 'type', 'status', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listRes?.meta.total || 1,
            noBaseCol: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        /> */}
        <PackageTable
          roleIndex={roleIndex}
        />
        <PackageTable
          roleIndex={roleIndex}
          isInactiveRecord
        />
      </div>
    </>
  );
};

export default PackageManagement;
