import {
  FileAddOutlined,
  FileDoneOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, DatePicker, message, Modal, Row, Space, Spin, Switch, Typography
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PromotionCodeTable from './PromotionCodeTable';

import { DropdownElement } from 'common/components/DropdownType';
import ErrorText from 'common/components/ErrorText';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { StatusPromotionLabel } from 'common/components/StatusLabel';
import {
  createPromotionService,
  getPromotionByIdService,
  updatePromotionService,
  updateStatusPromotionService
} from 'common/services/extends/promotions';
import {
  EPromotionStatus, EPromotionTypes, EPromotionUserGroups, TPromotionRequest
} from 'common/services/extends/promotions/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { detectError } from 'common/utils/functions';
import { useSchema } from 'common/utils/schemas';

type TFormReturn = TPromotionRequest;
const defaultValues = {
  name: '',
  type: EPromotionTypes.BuyXGetY,
  userGroup: EPromotionUserGroups.All,
  buyAmountX: 0,
  freeAmountY: 0,
  customerIds: [],
  totalCodes: 0,
  description: '',
  discountCode: '',
  discountPercentage: 0,
  isCumulative: false,
  xHoursRate: 0,
  startDate: new Date(`${moment().format('YYYY-MM-DD')} 00:00:00`).toISOString(),
  endDate: undefined,
};
const ignoreStatus = [EPromotionStatus.Active, EPromotionStatus.Inactive];
const isResetFields: (keyof typeof defaultValues)[] = ['buyAmountX', 'freeAmountY', 'isCumulative', 'xHoursRate', 'discountPercentage'];

const PromotionDetail: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate, roleIndex
}) => {
  const { t } = useTranslation();
  const { promotionSchema } = useSchema();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));

  const [status, setStatus] = useState<number>(EPromotionStatus.New);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const promotionTypeOptions: OptionType[] = [
    {
      label: t('promotion.buyXGetY'),
      value: EPromotionTypes.BuyXGetY,
    },
    {
      label: t('promotion.xHoursRate'),
      value: EPromotionTypes.XHours,
    },
    {
      label: t('promotion.discountCode'),
      value: EPromotionTypes.DiscountCode,
    }
  ];

  const userGroupOptions: OptionType[] = [
    {
      label: t('promotion.all'),
      value: EPromotionUserGroups.All,
    },
    {
      label: t('promotion.specific'),
      value: EPromotionUserGroups.Specific,
    },
  ];

  const queryClient = useQueryClient();
  const queryKey = ['get-promotion-detail', idParams];
  const { data: promotionRes, isLoading: getPromotionLoading } = useQuery(
    queryKey,
    () => getPromotionByIdService(idParams),
    {
      enabled: !!idParams,
      onSuccess(data) {
        if (data) {
          setStatus(data.promotionProgramData.status);
        }
      },
    }
  );

  // Method
  const method = useForm<TFormReturn>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(promotionSchema),
  });

  // const { isDirty } = method.formState;

  // Flags
  const isDisabledByStatus = status === EPromotionStatus.Active
    || status === EPromotionStatus.Inactive;
  const isSpecificGroup = method.watch('userGroup') === EPromotionUserGroups.Specific;
  const isBuyXGetYType = method.watch('type') === EPromotionTypes.BuyXGetY;
  const isXHoursType = method.watch('type') === EPromotionTypes.XHours;
  const isDiscountCodeType = method.watch('type') === EPromotionTypes.DiscountCode;

  // Queries
  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['create-promotion'],
    (data: TPromotionRequest) => createPromotionService(data),
    {
      onSuccess: () => {
        navigate(`${ROUTE_PATHS.PROMOTION_MANAGEMENT}`);
      },
      onError: (err) => {
        message.error(t('message.createError'));
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(
            e.field as keyof TFormReturn,
            { message: e.message }
          ));
        }
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['update-promotion'],
    updatePromotionService,
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err) => {
        message.error(t('message.updateError'));
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('order.promotion')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(
            e.field as keyof TFormReturn,
            { message: e.message }
          ));
        }
      }
    }
  );

  const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(
    'updatePromotionStatus',
    async (data: {
      id: number, statusId: EPromotionStatus
    }) => {
      updateStatusPromotionService({ id: data.id, status: data.statusId });
    },
    {
      onSuccess: (_, params) => {
        setStatus(params.statusId);
        if (isOpen) setIsOpen(false);
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err) => {
        message.error(t('message.updateError'));
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('order.promotion')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(
            e.field as keyof TFormReturn,
            { message: e.message }
          ));
        }
      }
    }
  );

  const onSubmit = async (addingFn?: (id: number) => void) => {
    const formData = method.getValues();
    const {
      name,
      buyAmountX,
      customerIds,
      description,
      discountCode,
      discountPercentage,
      freeAmountY,
      isCumulative,
      totalCodes,
      type,
      userGroup,
      xHoursRate,
      startDate,
      endDate
    } = formData;

    const requestData = {
      name,
      buyAmountX,
      customerIds,
      description,
      discountPercentage,
      freeAmountY,
      isCumulative,
      totalCodes,
      type,
      userGroup,
      ...userGroup === EPromotionUserGroups.All && {
        discountCode,
      },
      ...type === EPromotionTypes.XHours && {
        xHoursRate
      },
      startDate: new Date(`${moment(startDate).format('YYYY-MM-DD')} 00:00:00`).toISOString(),
      endDate: endDate ? new Date(`${moment(endDate).format('YYYY-MM-DD')} 23:59:59`).toISOString() : null
    };
    if (idParams) {
      updateMutate({
        id: idParams,
        reqData: requestData
      });
    } else {
      createMutate(requestData, {
        onSuccess: (res) => {
          if (addingFn) addingFn(res.data.id);
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeStatusAndSave = async (stat: number) => {
    const isValid = await method.trigger();
    if (!isValid) return;
    if (!idParams) {
      await onSubmit((id) => {
        updateStatusMutate({ id, statusId: stat });
      });
    } else {
      await onSubmit();
      updateStatusMutate({ id: idParams, statusId: stat });
    }
  };

  useEffect(() => {
    if (promotionRes) {
      method.reset({
        name: promotionRes.promotionProgramData.name,
        type: promotionRes.promotionProgramData.type,
        userGroup: promotionRes.promotionProgramData.userGroup,
        buyAmountX: promotionRes.promotionProgramData.buyAmountX || 0,
        freeAmountY: promotionRes.promotionProgramData.freeAmountY || 0,
        description: promotionRes.promotionProgramData.description || '',
        customerIds: promotionRes.promotionProgramData.customerIds || [],
        discountCode: promotionRes.promotionProgramData.discountCode || '',
        discountPercentage: promotionRes.promotionProgramData.discountPercentage || 0,
        totalCodes: promotionRes.promotionProgramData.totalCodes || 0,
        xHoursRate: promotionRes.promotionProgramData.xHoursRate || 0,
        isCumulative: promotionRes.promotionProgramData.isCumulative,
        startDate: moment(promotionRes.promotionProgramData.startDate).toISOString(),
        endDate: promotionRes.promotionProgramData.endDate
          ? moment(promotionRes.promotionProgramData.endDate).toISOString() : undefined,
      });
    }
  }, [promotionRes, method]);

  const renderStatusButtons = useMemo(() => {
    switch (status) {
      case EPromotionStatus.Waiting:
        return (
          <Space>
            <Button
              loading={updateStatusLoading}
              disabled={!roleUpdate}
              className="btn-approved"
              onClick={() => setIsOpen(true)}
            >
              <FileDoneOutlined />
              {t('system.saveApproved')}
            </Button>
            {
              idParams ? (
                <Button
                  disabled={!roleUpdate}
                  loading={updateStatusLoading}
                  className="btn-inactive"
                  onClick={() => handleChangeStatusAndSave(EPromotionStatus.Inactive)}
                >
                  <SaveOutlined />
                  {t('system.inactive')}
                </Button>
              ) : null
            }
          </Space>
        );
      case EPromotionStatus.Active:
        return (
          idParams ? (
            <Button
              disabled={!roleUpdate}
              loading={updateStatusLoading}
              className="btn-inactive"
              onClick={() => handleChangeStatusAndSave(EPromotionStatus.Inactive)}
            >
              <SaveOutlined />
              {t('system.inactive')}
            </Button>
          ) : null
        );
      case EPromotionStatus.Inactive:
        return (
          <Button
            loading={updateStatusLoading}
            disabled={!roleUpdate}
            className="btn-approved"
            onClick={() => setIsOpen(true)}
          >
            <FileDoneOutlined />
            {t('system.saveApproved')}
          </Button>
        );
      default:
        return (
          <Button
            loading={updateStatusLoading}
            className="btn-sendApprove"
            onClick={() => handleChangeStatusAndSave(EPromotionStatus.Waiting)}
          >
            <FileAddOutlined />
            {t('system.sendApprove')}
          </Button>
        );
    }
  }, [status, updateStatusLoading, roleUpdate, handleChangeStatusAndSave, idParams, t]);

  return (
    <FormProvider<TFormReturn> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('promotion.edit') : t('promotion.create')}
        rightHeader={(
          <Space size={16}>
            {renderStatusButtons}
            {/* <StatusButtons
              loading={updateStatusLoading}
              status={status}
              isApprove={roleOther.includes(roles.PROMOTION_APPROVED)}
              handleChangeStatus={(stat) => {
                if (confirmStatus.includes(stat)) {
                  setIsOpen(true);
                } else {
                  handleChangeStatusAndSave(stat);
                }
              }}
            />
            {idParams && status !== EPromotionStatus.Inactive ? (
              <Button
                disabled={!roleUpdate}
                loading={updateStatusLoading}
                className="btn-inactive"
                onClick={() => handleChangeStatusAndSave(EPromotionStatus.Inactive)}
              >
                <SaveOutlined />
                {t('system.inactive')}
              </Button>
            ) : null} */}
            {!ignoreStatus.includes(status) && (
              <Button
                type="primary"
                disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
                loading={updateLoading || createLoading}
                onClick={method.handleSubmit(() => onSubmit())}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin spinning={getPromotionLoading}>
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text strong>
                      {t('aiServer.name')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="name"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          name="name"
                          placeholder="Nhập Thông tin"
                          value={value}
                          onChange={onChange}
                          disabled={isDisabledByStatus}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <Typography.Text strong>
                      {t('promotion.type')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="type"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <DropdownElement
                          options={promotionTypeOptions}
                          placeholder={`${t('system.select')} ${t('promotion.type')}`}
                          locale="vi"
                          size="large"
                          value={value}
                          disabled={isDisabledByStatus}
                          onChange={(e) => {
                            isResetFields.forEach((key) => method.resetField(key, {
                              defaultValue: defaultValues[key]
                            }));
                            onChange(e);
                          }}
                          error={error?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('promotion.userGroup')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="userGroup"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <DropdownElement
                          options={userGroupOptions}
                          placeholder={`${t('system.select')} ${t('promotion.userGroup')}`}
                          locale="vi"
                          size="large"
                          value={value}
                          disabled={isDisabledByStatus}
                          onChange={onChange}
                          error={error?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('promotion.customerIds')}
                      {' '}
                    </Typography.Text>
                    {isSpecificGroup && (
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                    )}
                    <Controller
                      name="customerIds"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <DropdownElement
                          isShowSearch
                          type="customer"
                          placeholder={`${t('system.select')} ${t('promotion.userGroup')}`}
                          locale="vi"
                          value={value}
                          disabled={isDisabledByStatus || !isSpecificGroup}
                          size="large"
                          onChange={onChange}
                          multiple={{
                            allowClear: true,
                          }}
                          error={error?.message}
                        />
                      )}
                    />
                  </Col>
                  {!isSpecificGroup && (
                    <>
                      <Col span={12} className="u-mt-16">
                        <Typography.Text strong>
                          {t('promotion.promotionCode')}
                          {' '}
                        </Typography.Text>
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                        <Controller
                          name="discountCode"
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="discountCode"
                              placeholder={`${t('system.input')} ${t('promotion.discountCode')}`}
                              value={value}
                              onChange={onChange}
                              disabled={isSpecificGroup || isDisabledByStatus}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      </Col>
                      <Col span={12} className="u-mt-16">
                        <Typography.Text strong>
                          {t('promotion.totalCodes')}
                          {' '}
                        </Typography.Text>
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                        <Controller
                          name="totalCodes"
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              type="number"
                              name="totalCodes"
                              placeholder={`${t('system.input')} ${t('promotion.totalCodes')}`}
                              value={value}
                              disabled={isDisabledByStatus}
                              onChange={(e) => onChange(Number(e.target.value))}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      </Col>
                    </>
                  )}
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.startDate')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="startDate"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <DatePicker
                            className="u-mt-8"
                            size="large"
                            value={moment(value)}
                            disabledDate={(date) => ((method.watch('endDate') ? date > moment(method.watch('endDate')) : false) || date.isBefore(`${moment().format('YYYY-MM-DD')} 00:00:00`))}
                            onChange={onChange}
                            format="DD/MM/YYYY"
                            defaultValue={moment()}
                            allowClear={false}
                            disabled={isDisabledByStatus}
                            style={{ width: '100%' }}
                            status={error?.message ? 'error' : undefined}
                          />
                          {
                            error && (
                              <ErrorText>
                                {error.message}
                              </ErrorText>
                            )
                          }
                        </>
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-16">
                    <Typography.Text strong>
                      {t('package.endDate')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="endDate"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <DatePicker
                            className="u-mt-8"
                            size="large"
                            value={value ? moment(value) : undefined}
                            disabledDate={(date) => date < moment(method.watch('startDate'))}
                            onChange={onChange}
                            format="DD/MM/YYYY"
                            style={{ width: '100%' }}
                            disabled={isDisabledByStatus}
                            status={error?.message ? 'error' : undefined}
                          />
                          {
                            error && (
                              <ErrorText>
                                {error.message}
                              </ErrorText>
                            )
                          }
                        </>
                      )}
                    />
                  </Col>
                  <Col span={24} className="u-mt-16">
                    <Typography.Text strong>
                      {t('promotion.description')}
                      {' '}
                    </Typography.Text>
                    <Controller
                      name="description"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextArea
                            className="u-mt-8"
                            name="description"
                            placeholder="Nhập mô tả"
                            value={value}
                            disabled={isDisabledByStatus}
                            onChange={onChange}
                            size="large"
                          />
                          {error && <div className="u-mt-8 u-text-red-500">{error?.message}</div>}
                        </>
                      )}
                    />
                  </Col>
                </Row>
                <Card
                  style={{ marginTop: 16 }}
                  title={(
                    <Space size={16} style={{ width: '100%', justifyContent: 'space-between' }}>
                      <Typography.Text strong>
                        {t('promotion.buyXGetYConfig')}
                      </Typography.Text>
                      <div>
                        <Typography.Text strong>
                          {t('promotion.isCumulative')}
                          {' '}
                        </Typography.Text>
                        <Controller
                          name="isCumulative"
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <>
                              <Switch
                                disabled={!isBuyXGetYType || isDisabledByStatus}
                                checked={value}
                                onChange={onChange}
                              />
                              {error && <div className="u-mt-8 u-text-red-500">{error?.message}</div>}
                            </>
                          )}
                        />
                      </div>
                    </Space>
                  )}
                >
                  <Row gutter={16}>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('promotion.buyAmountX')}
                        {' '}
                      </Typography.Text>
                      {isBuyXGetYType && (
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      )}
                      <Controller
                        name="buyAmountX"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            type="number"
                            name="buyAmountX"
                            placeholder={`${t('system.input')} ${t('promotion.buyAmountX')}`}
                            value={value}
                            onChange={(e) => onChange(Number(e.target.value))}
                            disabled={!isBuyXGetYType || isDisabledByStatus}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-16">
                      <Typography.Text strong>
                        {t('promotion.freeAmountY')}
                        {' '}
                      </Typography.Text>
                      {isBuyXGetYType && (
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      )}
                      <Controller
                        name="freeAmountY"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            type="number"
                            name="freeAmountY"
                            placeholder={`${t('system.input')} ${t('promotion.freeAmountY')}`}
                            value={value}
                            onChange={(e) => onChange(Number(e.target.value))}
                            disabled={!isBuyXGetYType || isDisabledByStatus}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
                <Card style={{ marginTop: 16 }} title={t('promotion.xHoursRateConfig')}>
                  <Row>
                    <Col span={24} className="u-mt-16">
                      <Typography.Text strong>
                        {t('promotion.xHoursRate')}
                        {' '}
                      </Typography.Text>
                      {isXHoursType && (
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      )}
                      <Controller
                        name="xHoursRate"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            type="number"
                            name="xHoursRate"
                            placeholder={`${t('system.input')} ${t('promotion.xHoursRate')}`}
                            value={value}
                            onChange={(e) => onChange(Number(e.target.value))}
                            disabled={!isXHoursType || isDisabledByStatus}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
                <Card style={{ marginTop: 16 }} title={t('promotion.discountConfig')}>
                  <Row gutter={16}>
                    <Col span={24} className="u-mt-16">
                      <Typography.Text strong>
                        {t('promotion.discountPercentage')}
                        {' '}
                      </Typography.Text>
                      {
                        isDiscountCodeType && (
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                        )
                      }
                      <Controller
                        name="discountPercentage"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            type="number"
                            name="discountPercentage"
                            placeholder={`${t('system.input')} ${t('promotion.discountPercentage')}`}
                            value={value}
                            onChange={(e) => onChange(Number(e.target.value))}
                            disabled={!isDiscountCodeType || isDisabledByStatus}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <div className="u-mb-16">
                {status && <StatusPromotionLabel status={status} bigger />}
              </div>
              <ManagementInfo
                createdDate={promotionRes ? moment(promotionRes?.promotionProgramData?.createdAt).fromNow() : ''}
                createdBy={promotionRes?.creator?.name || ''}
                lastUpdated={promotionRes ? moment(promotionRes?.promotionProgramData?.updatedAt).fromNow() : ''}
                lastUpdatedBy={promotionRes?.updater?.name || ''}
              />
            </Col>
            {idParams && roleIndex ? (
              <Col className="u-mt-16">
                <PromotionCodeTable
                  roles={{
                    roleIndex,
                    roleUpdate
                  }}
                  userGroup={method.watch('userGroup')}
                />
              </Col>
            ) : null}
          </Row>
        </Spin>
      </div>
      <Modal
        visible={isOpen}
        title={t('promotion.confirmApprove')}
        onCancel={() => setIsOpen(false)}
        onOk={() => method
          .handleSubmit(() => handleChangeStatusAndSave(EPromotionStatus.Active))()}
      >
        <Typography.Text strong>
          {t('promotion.confirmApproveMessage')}
        </Typography.Text>
      </Modal>
    </FormProvider>
  );
};

export default PromotionDetail;
