import {
  message,
  Modal, Space, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { StatusHandleButtons, StatusHandleLabel } from 'common/components/StatusLabel';
import { updateStatusSubmitFormByIdService } from 'common/services/forms';
import { SubmitFormByIdData, UpdateStatusSubmitFormByIdParams } from 'common/services/forms/types';

interface UpdateSubmitFormModalProps {
  open: boolean;
  handleClose: () => void;
  submitForm?: SubmitFormByIdData;
}

const UpdateSubmitFormModal: React.FC<UpdateSubmitFormModalProps> = ({
  submitForm, open, handleClose
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* React-query */
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['update-status-submit-form'],
    async (data: UpdateStatusSubmitFormByIdParams) => updateStatusSubmitFormByIdService({
      ...data
    }),
    {
      onSuccess: () => {
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(['submit-form-list-by-form']);
        handleClose();
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  return (
    <Modal
      title={<Typography.Title level={3}>{t('formManagement.submitFormDetail')}</Typography.Title>}
      visible={open}
      centered
      onCancel={handleClose}
      width={800}
      footer={null}
    >
      {
        submitForm ? (
          <div className="t-contactManagement_updateModal_form">
            <div className="t-contactManagement_updateModal_info">
              <Typography.Text strong>
                ID:
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.id}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Typography.Text strong>
                {t('system.name')}
                :
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.fields.name}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Typography.Text strong>
                {t('system.email')}
                :
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.fields.email || ''}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Typography.Text strong>
                {t('system.phone')}
                :
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.fields.phone || ''}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Typography.Text strong>
                {t('system.subject')}
                :
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.fields.subject || ''}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Typography.Text strong>
                {t('formManagement.corporation')}
                :
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.fields.corporation || ''}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Typography.Text strong>
                {t('system.content')}
                :
                {' '}
              </Typography.Text>
              <Typography.Text>
                {submitForm?.submittedData.fields.content || ''}
              </Typography.Text>
            </div>
            <div className="t-contactManagement_updateModal_info u-mt-8">
              <Space>
                <Typography.Text strong>
                  {t('system.status')}
                  :
                  {' '}
                </Typography.Text>
                {
                  submitForm?.submittedData.status && (
                    <StatusHandleLabel status={submitForm.submittedData.status} />
                  )
                }
                <StatusHandleButtons
                  loading={updateLoading}
                  status={submitForm?.submittedData.status}
                  handleChangeStatus={(stat) => {
                    updateMutate({ id: submitForm.submittedData.id, status: stat });
                  }}
                />
              </Space>
            </div>
          </div>
        ) : null
      }
    </Modal>
  );
};

export default UpdateSubmitFormModal;
