import { MailOutlined } from '@ant-design/icons';
import {
  Button, Card, message, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusPromotionCodeLabel } from 'common/components/StatusLabel';
import { getPromotionCodesByIdService, notifyPromotionService } from 'common/services/extends/promotions';
import { EPromotionCodeStatus, EPromotionUserGroups, TPromotionCode } from 'common/services/extends/promotions/types';
import { detectError, formatDateTime } from 'common/utils/functions';

export type TableDataTypes = TPromotionCode['promotionProgramCodeData'];

const PromotionCodeTable: React.FC<{ roles: Pick<ActiveRoles, 'roleIndex' | 'roleUpdate'>, userGroup: EPromotionUserGroups }> = ({
  roles, userGroup
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    if (searchParams.has('id')) delete paramsObj.id;
    return paramsObj;
  }, [searchParams]);
  const idParam = Number(searchParams.get('id'));
  const pageParam = searchParams.get('page');
  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);
  // const { roles: role } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedRows, setSelectedRows] = useState<TableDataTypes[]>([]);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const queryKey = ['promotion-code-list', keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getPromotionCodesByIdService({
      id: idParam,
      params: {
        keyword, page: currentPage, limit: currentView, ...returnParamsFilter
      }
    }),
    {
      enabled: !!(idParam && roles.roleIndex),
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: notifyMutate, isLoading: notifyLoading } = useMutation(
    ['notify-promotion'],
    notifyPromotionService,
    {
      onSuccess: () => {
        message.success(t('message.notifySuccess'));
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err)) {
          err.map((e) => message.error(e.message));
        }
      }
    }
  );

  const onNotify = () => {
    notifyMutate({
      id: idParam,
      data: {
        ...userGroup === EPromotionUserGroups.All && {
          emails: selectedRows.map((v) => v.customer.email),
        },
        ...userGroup === EPromotionUserGroups.Specific && {
          promotionProgramCodeIds: selectedRows.map((v) => v.id)
        }
      }
    });
  };

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      ...val.promotionProgramCodeData
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('promotion.promotionCode'),
      dataIndex: 'promotionCode',
      key: 'promotionCode',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.promotionProgramCode}
        </Typography.Text>
      ),
    },
    // --- name
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.customer?.name}
        </Typography.Text>
      ),
    },
    // --- email
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.customer?.email}
        </Typography.Text>
      ),
    },
    // --- is sent email
    {
      title: t('promotion.isSentEmailTitle'),
      dataIndex: 'isSentEmail',
      key: 'isSentEmail',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.isSentMail ? t('promotion.isSentEmail') : t('promotion.isNotSentEmail')}
        </Typography.Text>
      ),
    },
    // --- status
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        EPromotionCodeStatus[data.status] ? <StatusPromotionCodeLabel status={data.status} /> : '-'
      ),
    },
    // --- redeemed date
    {
      title: t('promotion.redeemedDate'),
      dataIndex: 'redeemedDate',
      key: 'redeemedDate',
      align: 'center',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemedDate)}
        </Typography.Text>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('promotion-codes', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
      ...idParam && { id: idParam.toString() }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <Card title={(
      <Space direction="horizontal" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Title level={4}>{t('promotion.promotionCodes')}</Typography.Title>
        <Button type="primary" onClick={onNotify} disabled={!roles.roleUpdate || (userGroup === EPromotionUserGroups.Specific && selectedRows.length === 0)} loading={notifyLoading}>
          <MailOutlined />
          {t('promotion.notifyPromotion')}
        </Button>
      </Space>
    )}
    >
      <PageTable
        handleSearch={setKeyword}
        handleSelectedRowKey={(data) => setSelectedRows(data)}
        isLoading={isLoading}
        isHideBulkActions
        roles={{
          roleCreate: false,
          roleDelete: true,
          roleUpdate: false,
        }}
        tableProps={{
          initShowColumns: ['id', 'promotionCode', 'name', 'email', 'isSentEmail', 'status', 'redeemedDate'],
          columns,
          pageData: tableData || [],
          currentPage,
          pageSize: currentView,
          handleSetCurrentPage,
          handleSetCurrentView,
          total: listRes?.meta.total || 1,
          noBaseCol: true,
          filterFields,
        }}
        filtersDataTable={{
          handleFilter,
          selectedFilterList,
          handleDeleteFilter
        }}
      />
    </Card>
  );
};

export default PromotionCodeTable;
