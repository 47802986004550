import axiosInstance from '../common/instance';

import {
  CreateFormManagementParams,
  DeleteFormManagementParams,
  FormManagementDataType,
  FormManagementDetailType,
  FormManagementUserType,
  GetAllFormManagementParams,
  GetFormManagementParams,
  SubmitFormByIdData,
  SubmitFormListByForm,
  SubmitFormListByFormParams,
  TypeFormManagementDataType,
  UpdateFormManagementParams,
  UpdateStatusSubmitFormByIdParams
} from './types';

export const getAllFormManagementService = async (
  params?: GetAllFormManagementParams
): Promise<APIPaginationResponse<FormManagementDataType[]>> => {
  const res = await axiosInstance.get('form-managements', { params });
  return res.data;
};

export const getFormManagementService = async (
  params: GetFormManagementParams,
): Promise<FormManagementDetailType> => {
  const { id, ...rest } = params;
  const res = await axiosInstance.get(`form-managements/${id}`, { params: rest });
  return res.data.data;
};

export const createFormManagementService = async (
  params: CreateFormManagementParams,
): Promise<FormManagementDataType> => {
  const res = await axiosInstance.post('form-managements', params);
  return res.data.data;
};

export const updateFormManagementService = async (
  params: UpdateFormManagementParams,
): Promise<FormManagementDataType> => {
  const { id, ...rest } = params;
  const res = await axiosInstance.put(`form-managements/${id}`, { ...rest });
  return res.data.data;
};

export const deleteFormManagementService = async (
  params: DeleteFormManagementParams,
): Promise<FormManagementDataType> => {
  const res = await axiosInstance.delete('form-managements', { data: params });
  return res.data.data;
};

export const getFormManagementUsersService = async (): Promise<FormManagementUserType[]> => {
  const res = await axiosInstance.get('form-managements/users');
  return res.data.data;
};

export const getAllTypeFormManagementService = async (
  params?: GetAllFormManagementParams
): Promise<APIPaginationResponse<TypeFormManagementDataType[]>> => {
  const res = await axiosInstance.get('submit-forms', { params });
  return res.data;
};

export const getAllSubmitFormListByFormService = async (
  params: SubmitFormListByFormParams
): Promise<SubmitFormListByForm> => {
  const { id, ...rest } = params;
  const res = await axiosInstance.get(`submit-forms/list-by-forms/${id}`, { params: rest });
  return res.data;
};

export const getSubmitFormByIdService = async (
  id: number
): Promise<SubmitFormByIdData> => {
  const res = await axiosInstance.get(`submit-forms/${id}`);
  return res.data.data;
};

export const updateStatusSubmitFormByIdService = async (
  params: UpdateStatusSubmitFormByIdParams
): Promise<TypeFormManagementDataType> => {
  const { id, status } = params;
  const res = await axiosInstance.put(`submit-forms/${id}/update-status`, { status });
  return res.data;
};

export const updateStatusSubmitFormWithManyIdService = async (
  ids: number[],
  status: number
): Promise<TypeFormManagementDataType> => {
  const res = await axiosInstance.put('submit-forms/update-status-with-many-id', { status, ids });
  return res.data;
};
